import '../../css/platform/functions/functionthree/my-page.css';

import { NavLink } from 'react-router-dom';
import Container from "../../components/platform/Container";
import React from 'react';

export default class Profile extends React.Component{
    render(){
        return(
            <Container>
                <div className="leftMenu">
                    <div>
                        <div className="menuGroup">계정</div>
                        <ul>
                            <NavLink to="/profile" exact activeClassName="selected">내 프로필</NavLink>
                            <NavLink to="/profile/agency-user-list" activeClassName="selected">소속기관 계정 목록</NavLink>
                            <NavLink to="/profile/terminate" activeClassName="selected">회원 탈퇴</NavLink>
                        </ul>
                    </div>
                    <div>
                        <div className="menuGroup">유동인구 분석 현황</div>
                        <ul>
                            <NavLink to="/profile/analysis-places-list" exact activeClassName="selected">분석 장소 목록</NavLink>
                            <NavLink to="/profile/enroll-new-place" exact activeClassName="selected">신규 장소 등록</NavLink>
                            <NavLink to="/profile/request-share" exact activeClassName="selected">공유 요청</NavLink>
                        </ul>
                    </div>
                    <div>
                        <div className="menuGroup">기타</div>
                        <ul>
                            <NavLink to="/profile/f_a_q" exact activeClassName="selected">FAQ 및 서비스 이용 문의</NavLink>
                            <NavLink to="/profile/app-download" exact activeClassName="selected">앱 설치 링크 안내</NavLink>
                            <NavLink to="/profile/manual-download" exact activeClassName="selected">이용 매뉴얼 다운로드</NavLink>
                            <NavLink to="/profile/terms-a-conditions" exact activeClassName="selected">이용약관</NavLink>
                        </ul>
                    </div>
                </div>
                <div className="rightPage">
                    {this.props.children}
                </div>
            </Container>
        )
    }
}
