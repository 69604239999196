import React from 'react';
import Chart from "react-apexcharts";

/*
    Hourly floation population chart for today.
    오늘 시간대별 유동인구 꺾은선 그래프
*/

class LineChartToday extends React.Component {
    constructor(props) {
      super(props);

      let time_arr = props.isSmall ? 
      ['0시', '', '', '', '', '', '', '', '', '', '', '', '12시', '', '', '', '', '', '', '', '', '', '', '23시']
      : ['0시', '1시', '2시', '3시', '4시', '5시', '6시', '7시', '8시', '9시', '10시', '11시', '12시', '13시', '14시', '15시', '16시', '17시', '18시', '19시', '20시', '21시', '22시', '23시'];

      let legend_show = props.isSmall ? true : true; //범례

      this.state = {
          series: [
            {
              name: "어제",
              data: [28, 29, 33, 36, 32, 32, 33, 28, 29, 33, 36, 32, 32, 33, 11, 14, 18, 17, 13 , 33, 36, 32, 33, 11]
            },
            {
              name: "오늘",
              data: [12, 11, 14, 18, 17, 13, 13, 12, 11, 14, 18, 17, 13 ]
            }
          ],
          options: {
            chart: {
            height: 250,
            type: 'line',
            toolbar: false,
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false,
            textAnchor: 'start'
          },
          stroke: {
            curve: 'straight',
            width: 2
          },
         
          grid: {
            row: {
              colors: ['transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
            padding: {
              left: -5,
              right: 5,
              top: -20,
             },
          },
          legend:{
            show: legend_show,
            horizontalAlign: 'right',
            position: 'top',
            offsetX: 0,
            offsetY: 10,
          },
          xaxis: {
            categories: time_arr,
          },
        },
        isSmall: props.isSmall,
      };
    }

    render() {
        return (
            <Chart 
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={this.state.isSmall ? "160" : "350"}
            width="98%"/>
        );
      }
}

export default LineChartToday;