import React from 'react';
import Container from "../../components/platform/Container";
import withMountEvent from "../../components/withMountEvent";
import classNames from "classnames";
import LineChartToday from "../../components/platform/functions/charts/LineChartToday";
import BarChartProgress from "../../components/platform/functions/charts/BarChartProgress";
import PopulationReport from "../../components/platform/functions/functiontwo/PopulationReport";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {RiBookmarkFill, RiBookmarkLine} from "react-icons/ri";

import video2 from "../../resources/temp_res/test.MP4";

import 'animate.css'
import '../../css/platform/functions/function-two.css';

// const position = [35.1707432012001, 129.12694931030273];

const sensor = ['더블콘', '야외강장', '추가하기'];
const period = ['일간', '주간', '월간'];

class DataAnalyze extends React.Component {

  state = {
    bookMark: false,
    selectedSensor: sensor[0],
    selectedPeriod: period[0],
    btn: false,
  }

  setBookMark = (value) => this.setState({ bookMark: value });
  setSelectedSensor = (value) => this.setState({ selectedSensor: value });
  setSelectedPeriod = (value) => this.setState({ selectedPeriod: value });
  setBtn = (value) => this.setState({ btn: value });

  btnSensorHandler = (key) => this.setSelectedSensor(key);
  btnPeriodHandler = (key) => this.setSelectedPeriod(key);

  save = () => {
    this.setState({btn: false}, () => {
      setTimeout(() => {
        this.savePDF();
      }, 200);
    });
  }

  savePDF = () => {
    const input = document.getElementById('right_side');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('report.pdf', {returnPromise: true}) ? this.setBtn(this.state.btn) : this.setBtn(!this.state.btn)
      })
  }

  render() {
    return (
      <Container>
        <div id="dt_anl_wrapper">
          <div id="left_side">
            <span id="btnBookMark" onClick={() =>{this.setBookMark(!this.state.bookMark)}}> {this.state.bookMark ? < RiBookmarkFill size={40}/>: < RiBookmarkLine size={40}/>} </span>

            <div id="place_wrapper">
              <div className="txt_gray">
                오늘 조회 수 81회
                <span className="tooltips">?</span>
              </div>
              <div id="place_description">
                <span id="place_name">영화의전당</span>
                <span id="place_address">해운대구 수영강변대로 140</span>
                <button id="btn_share" className="btn">공유시작하기</button>
              </div>
            </div>

            <div id="sensor_wrapper">
              <div>
                지금 <span className="txt_bold">더블콘</span>에 <span className="txt_bold">17명</span>의 방문객이 머물고 있습니다.
                <button className={classNames("btn", "download", this.state.btn? 'btn_loading': '')} onClick={this.save}><span className="download_txt">AI Report Download</span></button>
              </div>
              {sensor.map(key => <button className={classNames('btn', 'btn_sensor', key === this.state.selectedSensor ? 'btn_selected' : ' ')} onClick={() => this.btnSensorHandler(key)}>{key}</button>)}
            </div>

            {/*<div id="video_wrapper">*/}
            {/*  /!* <iframe src="https://my.matterport.com/show/?m=stjuPj9wYHq"></iframe> *!/*/}
            {/*  <video src={video2} controls="controls" autoPlay muted loop style={{height: '100%'}}> </video>*/}
            {/*</div>*/}
            <LineChartToday/>
          </div>
          <div id="right_side">
            <div id="trendByPeriod_wrapper">
              <div className="txt_bold_big">기간별 유동인구 추이 <span className="tooltips_t" data-content="Tip 직접 기간을 설정할 수 있어요!&#xa;일간/주간/월간 유동인구 추이가 궁금한 날짜를 선택해보세요">?</span></div>
              <div id="select_date">
                {period.map(key => <span className={ key === this.state.selectedPeriod ? 'date_selected' : ''} oßnClick={() => this.btnPeriodHandler(key)}>{key}</span>)}
              </div>
              <span className={classNames("txt_gray", "period")}> 선택기간 : 2021-07-21 ~ 2021-07-29 </span>
              <BarChartProgress style={{height: '30%'}}/>
              <div id="trendByPeriod_report" className="report_result">
                <div>* 선택 기간 동안 유동인구가 <span className="txt_bold">가장 많았던 주는 7월 4째주</span>로, <span>총 2001명</span>의 유동인구가 측정되었어요!</div>
                <div>* 선택 기간 동안 <span className="txt_bold">주차별 평균 유동인구는 1,242명</span>이에요!</div>
                <div>* 주말 : 1,234명 / 주중 : 2,456명 → 주말의 방문객이 주중보다 1,222명(31%) 많았어요!</div>
              </div>
            </div>
            <PopulationReport data="1"/>
            <PopulationReport data="2"/>
          </div>
        </div>
      </Container>
    )
  }
}

export default withMountEvent(DataAnalyze, 'DataAnalyze');