import { useContext, useState } from "react";
import { RequiredMark } from "../RequiredMark";
import { api_pcp } from "../../../api/api_pcp";
import { Button, TextField } from "@material-ui/core";
import { AlertContext } from "../../../contexts/AlertContext";
import Timer from "../../utilities/Timer";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";

import "../../../css/authentication/find-id.css";

export const ValidatePhoneNumber = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(props.profile.phone_number);
  const [authKey, setAuthKey] = useState("");
  const [isLockedSubmit, setLockedSubmit] = useState(false);
  const setAlert = useContext(AlertContext).setAlert;

  let profile = props.profile;

  const onPhoneNumberChange = (e) => {
    let value = e.target.value;
    value = value.replaceAll("_", "");
    setPhoneNumber(value);
  };

  const onAuthKeyChange = (e) => {
    setAuthKey(e.target.value);
  };

  const onClick = () => {
    if (phoneNumber === "" || phoneNumber.length < 11) {
      setAlert({
        type: "error",
        message: "Please enter full cellphone number",
      });
      return;
    }
    if (props.timer) {
      props.setTimer(false);
      setTimeout(() => {
        props.setTimer(true);
      }, 1000);
    } else {
      props.setTimer(true);
    }
    handleCheckPhoneNumber();
  };

  const handleCheckPhoneNumber = () => {
    if (!isLockedSubmit) {
      setLockedSubmit(true);
      api_pcp
        .post("/v1/sign-up/auth-code", { phone_number: phoneNumber })
        .then((res) => {
          if (!res.ok) {
            setAlert({
              type: "error",
              message: "Phone number is not valid, please try again later",
            });
            return;
          }

          setAlert({
            type: "success",
            message: "We have sent you authentication key!",
          });

          profile.request_key = res.data.request_key;
          profile.phone_number = phoneNumber;
          setLockedSubmit(false);
        });
    }
  };

  const onSubmit = () => {
    if (!authKey) {
      setAlert({
        type: "error",
        message: "Please enter authentication key!",
      });
      return;
    }

    if (authKey !== "123456") {
      setAlert({
        type: "error",
        message: "Authentication key is not correct, please try  again later!",
      });
      return;
    }
    setAlert({
      type: "success",
      message: "Successfully verified!",
    });
    props.setTimer(false);
    profile.auth_code = authKey;
    props.setProfile(profile);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={2}>
        <h1>
          <RequiredMark /> 휴대폰
        </h1>
      </Grid>
      <Grid item xs={12} md={5} container spacing={1}>
        <Grid item xs={6}>
          <TextField
            label="010-0000-0000"
            size="small"
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            variant={"outlined"}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={onClick}
            variant={"contained"}
            className={"signup-btn"}
          >
            인증문자 전송
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}></Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={5} container spacing={1}>
        <Grid item xs={6}>
          <TextField
            type={"text"}
            value={authKey}
            onChange={onAuthKeyChange}
            className={"signup-text-input auth-key"}
            variant={"outlined"}
            label={"인증번호입력"}
            size="small"
            InputProps={{
              inputComponent: NumberFormatAuthKey,
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          {props.timer ? <Timer className={"timer"} seconds={180} /> : null}
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            className={"signup-btn"}
            variant={"contained"}
            onClick={onSubmit}
          >
            학은
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###-####-####"
      mask="_"
    />
  );
}

function NumberFormatAuthKey(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="######"
    />
  );
}
