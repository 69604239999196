import { Button, Dialog, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { rexEngNumSp } from "../constants/RegularEpressions";
import { api_pcp } from "../api/api_pcp";
import { useHistory } from "react-router-dom";
import ValidateMark from "../components/auth/ValidateMark";

import '../css/modals/modal.css';

const  ResetPassword = (props) => {
    const [open, setOpen] = useState(false);
    const [pwdOne, setPwdOne] = useState('', {password: true});
    const [pwdTwo, setPwdTwo] = useState('', {password: true});
    const [validPwdOne, setValidPwdOne] = useState();
    const [validPwdTwo, setValidPwdTwo] = useState();
    const setAlert = props.setAlert;
    const history = useHistory();

    let profile = props.profile;

    useEffect(() => {
        setOpen(true)
    }, [])

    useEffect(() => {
        if (pwdOne){
            setValidPwdOne(rexEngNumSp.test(pwdOne));
            if(pwdTwo && pwdTwo === pwdOne){
                setValidPwdTwo(true);
            }else {
                setValidPwdTwo(false);
            }
        }else {
            setValidPwdOne(undefined);
            setValidPwdTwo(undefined)
        }
    }, [pwdOne, pwdTwo])

    const onChangePwdOne = (e) => {
        setPwdOne(e.target.value);
    }

    const onChangePwdTwo = (e) => {
        setPwdTwo(e.target.value);
    }

    const submitHandler = () => {
        if( !validPwdOne || !validPwdTwo) {
            setAlert({
                type: 'error',
                message: 'Please enter new passwords'
            });
            return ;
        }

        profile.new_password = pwdOne;

        api_pcp.post('/v1/password/reset', profile).then((r) => {
            if(!r.ok){
                setAlert({
                    type: 'error',
                    message: 'Authentication error'
                })
                return ;
            }


            setAlert({
                type: 'success',
                message: 'Password succesfully changed'
            });

            setTimeout(() => {
                history.push('sign-in')
            }, 1500)

        })
    }

    return (
        <Dialog open={open} maxWidth={'lg'}>
            <div className={'reset-pwd-modal'}>
                <div className={'input-group-header'}>
                    <h2>비밀번호 재설정</h2>
                    <p>개인정보의 보안을 위해 회원님이 앞으로 사용하실 새로운 비밀번호를 입력해주세요!</p>
                </div>
                <hr />
                <div className={'input-group'}>
                    <span className={'input-name'}>새로운 비밀번호 입력</span>
                    <TextField
                        label={'New password'}
                        type={'password'}
                        value={pwdOne}
                        onChange={onChangePwdOne}
                        variant={'outlined'}
                        className={'input-field'}
                        helperText={'*띄어쓰기 없는 영문, 숫자, 특수문자를 포함한 8~15자'}
                    />
                    {validPwdOne !== undefined ? <ValidateMark offset={{x: 10, y:25}} valid={validPwdOne} />: null}
                </div>
                <div className={'input-group'}>
                    <span className={'input-name'}>새로운 비밀번호 확인</span>
                    <TextField
                        label={'Confirm password'}
                        type={'password'}
                        value={pwdTwo}
                        onChange={onChangePwdTwo}
                        variant={'outlined'}
                        className={'input-field'}
                        helperText={" "}
                    />
                    {validPwdTwo !== undefined ? <ValidateMark offset={{x: 10, y:25}} valid={validPwdTwo} />: null}
                </div>
                <div>
                    <Button className={'submit-btn'} onClick={submitHandler} variant={"contained"}>
                        비밀번호 재설정 완료!
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default ResetPassword;