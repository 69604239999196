import React from 'react';

import {Button, Dialog, DialogContent, TextField} from "@material-ui/core";
import '../css/platform/functions/functionthree/modal.css';

const styles = {
    modal: {
        width: 500,
        height: 200,
        backgroundColor: "white",
        padding: "50px !important"
    },

    btn_wrapper: {
        float: "right",
        marginTop: 20,
    }
};

export default class RegisterPlaceModal extends React.Component {

    state = {
        placeValue: ''
    }

    closeModal = () => {
        this.props.close(false);
    }

    addPlace = () => {
        this.props.addPlace(this.state.placeValue);
        this.closeModal();
    }

    onKeyPress = (e) => {
        if(e.key === "Enter")
            this.addPlace();
    }

    render() {
        const { open } = this.props;
        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                    <DialogContent style={styles.modal}>
                        <div className="modal-title">신규 장소 등록</div>
                        <div>
                            <TextField
                                id="test"
                                autoComplete="off"
                                variant="outlined"
                                size="small"
                                style={{ height:30, width: '100%', marginTop: -10 }}
                                placeholder="장소명을 입력해주세요"
                                onChange={(e) => {this.setState({placeValue: e.target.value})}}
                                value={this.state.placeValue}
                                autoFocus
                                onKeyPress={this.onKeyPress}
                            />
                        </div>
                        <div style={styles.btn_wrapper}>
                            <Button variant="outlined" style={{width: 70, height: 40}} onClick={this.closeModal}>취소</Button>
                            <Button variant="outlined" style={{width: 70, height: 40, marginLeft: 10}} onClick={this.addPlace}>등록</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}