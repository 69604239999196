import React from 'react';
import { BiUserCircle } from "react-icons/bi";
import { Button, TextField } from '@material-ui/core';
import {inject} from "mobx-react";

import Profile from "../../../../../screens/platform/Profile";
import PasswordChangeModal from "../../../../../modals/PasswordChangeModal";
import AgencySelectModal from "../../../../../modals/AgencySelectModal";
import PhoneNumChangeModal from "../../../../../modals/PhoneNumChangeModal";
import {api_pcp} from "../../../../../api/api_pcp";
import {rexEmail} from "../../../../../constants/RegularEpressions";

class MyProfile extends React.Component{

    constructor(props) {
        super(props);
        this.state = ({
            changePassword: false,
            changeAgency: false,
            changePhone: false,
            agency: "BIFF(영화의전당)",
            selectEmail: -1,
            isFetching: true,
            user: {},
            group: {},
            edit_email: '',
            isEmailEdit: false,
            profileImg: null,
        })
    }

    setAgency = (data) => {
        this.setState({agency: data});
    }

    setDataChange = () => {
        this.fetch(true);
    }

    handleEmailChange = (e) => {
        this.setState({selectEmail: e.target.value})
    }

    componentDidMount() {
        this.fetch(true)
    }

    fetch = (ignoreFetching: boolean) => {
        if (this.state.isFetching && !ignoreFetching) return;
        this.setState({ isFetching: true }, () => {
            this.props.sessionStore.fetchMe().then(() => {
                console.log(this.props.sessionStore.user);
                this.setState({
                    user: this.props.sessionStore.user,
                    group: this.props.sessionStore.user.company ? this.props.sessionStore.user.company : this.props.sessionStore.user.organization,
                    edit_email: this.props.sessionStore.user.email
                })
            });
        })
    }

    loadImg = (e) => {
        if(e.target?.files[0]){
            this.setState({profileImg: URL.createObjectURL(e.target.files[0])});
        }
    }

    render() {
        return (
            <Profile>
                <div className="c_wrapper">
                    <div className="header">My Info.</div>
                    <div className="userInfo" style={{width: 1000}}>
                        <div>
                            <div className="item1">프로필</div>
                            <div className="item2">
                                <input type="file" hidden onChange={this.loadImg} id="sensor_file" ref={this.imgRef}/>
                                <label id="sensor_file_label" htmlFor="sensor_file">
                                    {this.state.profileImg !== null
                                        ? <img style={{width:80}} src={this.state.profileImg} alt='Profile'/>
                                        : <BiUserCircle size={80} style={{cursor: "pointer"}}/>
                                    }
                                </label>
                            </div>
                            <div className="item3">
                                <Button style={{color:"#ccc"}} onClick={() => {this.setState({profileImg: null})}}>프로필 삭제</Button>
                            </div>
                        </div>

                        <div>
                            <div className="item1">소속기관</div>
                            <div className="item2 txt_bold">{this.state.group.name}</div>
                            <div className="item3">
                                <Button onClick={() => this.setState({changeAgency: true})} style={{color:"#ccc"}}>소속기관 변경</Button>
                            </div>
                        </div>

                        <div>
                            <div className="item1">부서</div>
                            <div className="item2" style={{color: "#aaa"}}>부서명을 입력해주세요</div>
                        </div>

                        <div>
                            <div className="item1">아이디</div>
                            <div className="item2 txt_bold">{this.state.user.username}</div>
                        </div>

                        <div>
                            <div className="item1">비밀번호</div>
                            <div className="item2"></div>
                            <div className="item3">
                                <Button onClick={() => this.setState({changePassword: true})} style={{color:"#ccc"}}>비밀번호 변경</Button>
                            </div>
                        </div>

                        <div>
                            <div className="item1">휴대폰</div>
                            <div className="item2 txt_bold">{this.state.user?.phone_number?.replace(/(\d{3})(\d{4})(\d)/, "$1-$2-$3")}</div>
                            <div className="item3">
                                <Button style={{color:"#ccc"}} onClick={() => this.setState({changePhone: true})}>휴대폰 변경</Button>
                            </div>
                        </div>

                        <div>
                            <div className="item1" style={{color: this.state.isEmailEdit ? "green" : null}}>이메일</div>
                            <div className="item2">
                                <TextField
                                    id="test"
                                    autoComplete="off"
                                    variant="outlined"
                                    size="small"
                                    style={{ height:30, width: 400 }}
                                    value={this.state.edit_email}
                                    onChange={this.onEmailChange}
                                    placeholder="이메일 @ 주소 형식으로 적어주세요"
                                />
                            </div>
                        </div>
                        <Button variant="outlined" style={{width: 100, float:"right"}} disabled={!this.state.isEmailEdit} onClick={this.onEmailClick}>수정</Button>
                    </div>

                    <PasswordChangeModal open = {this.state.changePassword} close = {() => this.setState({changePassword: false})} />
                    <AgencySelectModal open = {this.state.changeAgency} close = {() => this.setState({changeAgency: false})} setDataChange={this.setDataChange}/>
                    <PhoneNumChangeModal open = {this.state.changePhone} close = {() => this.setState({changePhone: false})} setDataChange={this.setDataChange}/>
                </div>
            </Profile>
        )
    }

    onEmailChange = (e) => {
        this.setState({
            isEmailEdit: !(this.props.sessionStore.user.email === e.target.value),
            edit_email: e.target.value
        })
    }

    onEmailClick = () => {
        if(!rexEmail.test(this.state.edit_email))
            return alert('이메일 형식으로 적어주세요');

        api_pcp.put('/v1/user/me', {
            email: this.state.edit_email,
        }).then((res) => {
            if (!res.ok) {
                this.setState({edit_email: ''});
                return alert('이미 등록된 이메일입니다');
            }
            alert("변경되었습니다");
            this.fetch(true);
            this.setState({isEmailEdit: false});
        }).catch((err) => {
            console.err(err);
        })
    }
}

export default inject('sessionStore')(MyProfile);