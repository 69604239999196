import { useState } from "react";
import { api_pcp } from "../../api/api_pcp";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { CustomAlert } from "../../components/utilities/CustomAlert";
import { Loading } from "../../components/utilities/Loading";
import FindIdEmail from "../../components/auth/findid/FindIdEmail";
import FindIdPhoneNumber from "../../components/auth/findid/FindIdPhoneNumber";
import Timer from "../../components/utilities/Timer";
import NumberFormat from "react-number-format";
import ResetPasswordModal from "../../modals/ResetPasswordModal";
import OrgAndCompModal from "../../modals/OrgAndCompModal";
import Grid from "@mui/material/Grid";

import "../../css/authentication/find-id.css";

const FindPassword = () => {
  const [profile, setProfile] = useState({
    username: "",
    phone_number: "",
    email: "",
    request_key: "",
    auth_code: "",
    recovery_type: "phone-number",
    organization_uuid: "",
    company_uuid: "",
    agency_name: "",
  });

  const [timer, setTimer] = useState(false);
  const [modal, setModal] = useState(false);
  const [agencyModal, setAgencyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});

  const onChange = (e) => {
    let temp = { ...profile };
    temp.recovery_type = e.target.value;
    setProfile(temp);
    setTimer(false);
  };

  const submitClickHandler = () => {
    if (!loading) {
      if (!profile.username || !profile.auth_code || !profile.agency_name) {
        setAlert({
          type: "error",
          message: "Please enter fill required fields",
        });
        return;
      }
      if (profile.recovery_type === "phone-number") {
        if (!profile.phone_number) {
          setAlert({
            type: "error",
            message: "Please enter phone number",
          });
          return;
        }
      } else {
        if (!profile.email) {
          setAlert({
            type: "error",
            message: "Please enter email",
          });
          return;
        }
      }
      setLoading(true);
      api_pcp.post("/v1/password/reset", profile).then((r) => {
        setAlert({
          type: "success",
          message: "Successfully verified .You can set new password now!",
        });
        setTimeout(() => {
          setLoading(false);
          setModal(true);
          setTimer(false);
        }, 1500);
      });
    }
  };

  const AgencyModalHandler = () => {
    setAgencyModal(!agencyModal);
  };

  const idChangeHandler = (e) => {
    const value = e.target.value;
    if (/^[a-z][a-z0-9]*$/i.test(value) || /^\s*$/.test(value)) {
      const temp = { ...profile };
      temp.username = e.target.value;
      setProfile(temp);
    }
  };

  const keyChangeHandler = (e) => {
    const temp = { ...profile };
    temp.auth_code = e.target.value;
    setProfile(temp);
  };

  return (
    <Container maxWidth="md" style={{ padding: "0 2rem" }}>
      {modal && <ResetPasswordModal setAlert={setAlert} profile={profile} />}
      {loading && <Loading />}
      <CustomAlert alert={alert} />

      <Grid container paddingTop={"4rem"} spacing={2}>
        <Grid item xs={12}>
          <h1 className={"find-id-title"}>비밀번호 찾기</h1>
        </Grid>
        <Grid item xs={12}>
          <p className="find-id-lable">
            비밀번호는 암호화 저장되어 분실 시 찾아드릴 수 없는 정보입니다 T.T{" "}
            <br />
            아래의 본인 확인을 통해 비밀번호를 재설정해주세요!
          </p>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            className="find-password-radio-wrapper"
            row
            aria-label="position"
            onChange={onChange}
            name="member-type"
            value={profile.recovery_type}
          >
            <FormControlLabel
              value="phone-number"
              control={<Radio color="primary" />}
              label="등록된 휴대폰 번호로 찾기 "
            />
            <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="등록된 이메일로 찾기"
            />
          </RadioGroup>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <OrgAndCompModal
            open={agencyModal}
            handleClose={AgencyModalHandler}
            profile={profile}
            setProfile={setProfile}
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={1}>
              <h3 className={"input-name"}>소속</h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-multiline-flexible"
                value={profile.agency_name}
                variant="outlined"
                className={"org-name"}
                InputProps={{
                  readOnly: true,
                }}
                onClick={AgencyModalHandler}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={1}>
              <h3 className="input-name">아이디</h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label={"아이디을 입력해주세요!"}
                value={profile.username}
                onChange={idChangeHandler}
                variant={"outlined"}
                className={"org-name"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {profile.recovery_type === "phone-number" ? (
            <FindIdPhoneNumber
              profile={profile}
              setProfile={setProfile}
              setAlert={setAlert}
              timer={timer}
              setTimer={setTimer}
              resetPassword
            />
          ) : (
            <FindIdEmail
              profile={profile}
              setProfile={setProfile}
              submitButton
              timer={timer}
              setTimer={setTimer}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems={"center"}>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={6} md={2}>
              <TextField
                required
                id="standard-required"
                label="인증번호입력"
                value={profile.auth_code}
                onChange={keyChangeHandler}
                helperText="*인증번호 6자리를 입력해주세요!"
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
              />
            </Grid>
            <Grid item xs={3} md={1}>
              <div className={"timer"}>
                {timer ? <Timer seconds={180} /> : null}
              </div>
            </Grid>
            <Grid item xs={3} md={1}>
              <Button
                fullWidth
                onClick={submitClickHandler}
                variant={"contained"}
              >
                확인
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="######"
    />
  );
}

export default FindPassword;
// a = {"username":"zeroweb","phone_number":"01052780693","request_key":"ZDLFWONAMXCWPRLCJQKMUKVASRJIKLHEMMQWZVOSBTKDCOYMRNFTWJCEIWNYMLDZ","auth_key":"123456"}
