import { useContext, useState } from "react";
import { inject } from "mobx-react";
import { SignupEmail } from "../../components/auth/signup/SignupEmail";
import { ValidatePassword } from "../../components/auth/signup/ValidatePassword";
import { ValidateId } from "../../components/auth/signup/ValidateId";
import { ValidatePhoneNumber } from "../../components/auth/signup/ValidatePhoneNumber";
import { PrivacyAndPolicyValidator } from "../../components/auth/signup/PrivacyAndPolicyValidator";
import { OrgAndCompany } from "../../components/auth/signup/OrgAndCompany";
import { api_pcp } from "../../api/api_pcp";
import { AlertContext } from "../../contexts/AlertContext";
import SuccessModal from "../../modals/SuccessModal";
import Grid from "@mui/material/Grid";
// import {useHistory} from "react-router-dom";
import "../../css/authentication/signup.css";
import { Button, Container } from "@material-ui/core";
const SignUp = (props) => {
  const [profile, setProfile] = useState({
    agree_to_use: false,
    privacy_and_policy: false,
    user_data_collection: false,
    company_uuid: "",
    organization_uuid: "",
    username: "",
    password: "",
    email: "",
    phone_number: "",
    request_key: "",
    auth_code: "",
  });

  const [timer, setTimer] = useState(false);
  const [modal, setModal] = useState(false);
  const setAlert = useContext(AlertContext).setAlert;
  // const history = useHistory()

  var isLockedSubmit = false;
  const release = (callback) => {
    // setLoading(false)
    isLockedSubmit = false;
    typeof callback === "function" && callback();
  };

  const submitHandler = () => {
    if (!isLockedSubmit) {
      isLockedSubmit = true;
      if (!profile?.privacy_and_policy || !profile?.user_data_collection) {
        setAlert({
          type: "error",
          message: "Please Accept Privacy and Policy Terms",
        });
        isLockedSubmit = false;
        return;
      }

      for (const [key, value] of Object.entries(profile)) {
        console.log(key, value);
        if (value === "") {
          const k = key.replaceAll("_", " ");
          setAlert({
            type: "error",
            message: "Please Enter " + k,
          });
          isLockedSubmit = false;
          return;
        }
      }

      console.log(profile);
      api_pcp
        .get("/v1/sign-up/email/available", { q: profile.email })
        .then((r) => {
          if (!r.ok) {
            console.error("server error");
            return;
          }

          if (!r.data.available) {
            setAlert({
              type: "error",
              message: "Email is not valid or used by other account!",
            });
          }
        });
      props.sessionStore
        .signInWithToken(profile, true)
        .then(() => {
          try {
            props.sessionStore.fetchMe();
            setModal(true);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((e) => {
          switch (e) {
            case "TOKEN_NOT_EXISTS": {
              release({ error: "로그인 실패(T404)" });
              break;
            }
            default: {
              release({ error: "로그인 실패" });
              break;
            }
          }
        });
      isLockedSubmit = false;
    }
  };
  const Test = () => {
    const user = props.sessionStore.user;
    console.log(user);
    return (
      <div className="signup-success">
        {user ? (
          <>
            <h1>환영합니다!</h1>
            <p>{user.username}님, 회원가입을 축하합니다.</p>
            <span>
              {" "}
              <b>부산시 해운대구청 그룹의 28번째 </b>사용자이시군요~!{" "}
            </span>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Container maxWidth="md">
      {modal ? (
        <SuccessModal
          msg={""}
          component={Test}
          redirectLink="/population"
          redirectName="본격적으로 플랫폼을 사용해볼까요?"
        />
      ) : null}
      <Grid
        container
        flexDirection={"column"}
        spacing={3}
        marginTop={"4rem"}
        padding={"1rem"}
        paddingBottom={"4rem"}
      >
        <Grid item xs={12}>
          <p className="signup-header">회원가입</p>
        </Grid>
        <Grid item xs={12}>
          <p className="section-header">약관 동의 (필수)</p>
        </Grid>
        <Grid item xs={12}>
          <span className="sub-header">
            원활한 서비스 제공을 위한 최소한의 동의가 필요합니다.
          </span>
        </Grid>
        <Grid item xs={12}>
          <PrivacyAndPolicyValidator
            profile={profile}
            setProfile={setProfile}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <OrgAndCompany profile={profile} setProfile={setProfile} />
        </Grid>
        <Grid item xs={12}>
          <hr />
          <ValidateId profile={profile} setProfile={setProfile} />
        </Grid>
        <Grid item xs={12}>
          <ValidatePassword profile={profile} setProfile={setProfile} />
        </Grid>
        <Grid item xs={12}>
          <ValidatePhoneNumber
            profile={profile}
            setProfile={setProfile}
            timer={timer}
            setTimer={setTimer}
          />
        </Grid>

        <Grid item xs={12}>
          <SignupEmail profile={profile} setProfile={setProfile} />
        </Grid>
        <Grid item xs={12} container justifyContent={"end"}>
          <Grid item xs={12} md={2} paddingTop={"3rem"}>
            <Button
              onClick={submitHandler}
              variant="contained"
              fullWidth
              color="primary"
            >
              확인
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default inject(["sessionStore"])(SignUp);
