import React from 'react';
import { RiVipCrownFill } from "react-icons/ri";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

import Profile from "../../../../../screens/platform/Profile";
import {api_pcp} from "../../../../../api/api_pcp";
import {User} from "../../../../../models/User";
import moment from "moment";
import {inject} from "mobx-react";
import UserProfileModal from "../../../../../modals/UserProfileModal";

class AgencyUserList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isDesc: true,
            selectedUser: {},
            isFetching: true,
            total_count: 0,
            userList: [],
            user: {},
            memberName: '',
            numberAccounts: 0,
            numberMasters: 0,
            numberGenerals: 0,
            member: {}
        }
    }

    openUserProfile = (item) => {
        this.setState({
            selectedUser: item,
            isOpen: true
        });
    }

    sort = ( a, b ) => {
        let mult = this.state.isDesc ? 1 : -1;
        if ( a.created_at < b.created_at ){ return -1 * mult; }
        if ( a.created_at > b.created_at ){ return 1 * mult; }
        if ( a.username < b.username ){ return -1 * mult ; }
        if ( a.username > b.username ){ return 1 * mult; }
        return 0;
    }

    sortBySignUpAt = () => {
        this.setState({
            userList: this.state.userList.sort(this.sort),
            isDesc: !this.state.isDesc
        })
    }

    componentDidMount() {
        this.userLists();
    }

    userLists = () => {
        let isCompany = '';
        this.props.sessionStore.fetchMe().then(() => {
            this.setState({
                memberName: this.props.sessionStore.user.company ? this.props.sessionStore.user.company.name : this.props.sessionStore.user.organization.name,
            })
            isCompany = this.props.sessionStore.user.company ? "company" : "organization";
        }).then(() => {
            api_pcp.get(`/v1/user/me/${isCompany}/user/`, ).then((res) => {
                if (!res.ok) return alert('데이터를 불러오지 못했습니다.');
                let numberAccounts = (res.data.total_count || 0);
                let userList: User[] = [];
                let numberMasters = 0;
                (res.data.users || []).forEach((e) => {
                    userList.push(new User(e));
                    if(e.type === "SUPER_USER") ++numberMasters
                });

                this.setState({
                    numberAccounts,
                    userList,
                    numberMasters,
                    numberGenerals: numberAccounts - numberMasters
                })
            }).catch((err) => {
                console.error(err);
            })
        })
    }
    render(){
        return(
            <Profile>
                <div className="c_wrapper">
                    <div className="header">소속기관 계정 목록</div>
                    <div className="sub_title">
                        현재 <span className="txt_bold">{this.state.memberName}</span>에 등록되어 있는 계정은 총 <span className="txt_bold">{this.state.numberAccounts}개</span> 입니다.
                    </div>
                    <div style={{marginTop: 30}}>
                        마스터 계정은 {this.state.numberMasters}개, 일반 계정은 {this.state.numberGenerals}개 있습니다 <br/>
                        아이디 좌측의 아이콘을 클릭하여 소속기관의 계정에게 마스터 권한을 부여할 수 있습니다
                    </div>
                    <div style={{width: 1200, height: 500, marginTop: 30, marginBottom: 30,overflow: "auto"}}>
                        <Table stickyHeader aria-label="sticky table" style={{width: "100%"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ width: 400 }}>아이디</TableCell>
                                    <TableCell align="center" style={{ width: 400 }}>부서</TableCell>
                                    <TableCell align="center" style={{ width: 300, cursor: "pointer"}} onClick={this.sortBySignUpAt}>가입일자 {this.state.isDesc ? "▼" : "▲"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.userList.map(item => {
                                        return(
                                            <TableRow hover tabIndex={-1} key={item.username}>
                                                <TableCell align="center" style={{cursor: "pointer"}}>
                                                    <span onClick={() => this.openUserProfile(item)} className="icon-wrapper">
                                                        {item.type === "SUPER_USER" ? <RiVipCrownFill size={20} color="black" className="master-icon"/> : null}{item.username}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="center">{item.department}</TableCell>
                                                <TableCell align="center">{moment.unix(item.created_at).format("YYYY-MM-DD")}</TableCell>
                                            </TableRow>
                                        )})
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <UserProfileModal open = {this.state.isOpen} close = {() => this.setState({isOpen: false})} userProfile = {this.state.selectedUser}>
                    </UserProfileModal>
                </div>
            </Profile>
        )
    }
}

export default inject('sessionStore')(AgencyUserList);
