import React from 'react';
import PlatformNavbar from "./PlatformNavbar";
import {NavLink} from "react-router-dom";
import withMountEvent from "../withMountEvent";
import '../../css/platform/platform.css'
import '../../css/platform/platform-sidebar.css'

class Container extends React.Component {

  static propTypes = {

  };

  render() {
    return (
      <div className="pck-platform">
        <PlatformNavbar />

        <div className="pck-sidebar-map">
          <div className="pck-sidebar">
            <NavLink to="/population" exact activeClassName="selected">유동<br/>인구</NavLink>
            <NavLink to="/data-analyze" exact activeClassName="selected">데이터<br/>분석</NavLink>
            <NavLink to="/profile" activeClassName="selected">마이<br/>페이지</NavLink>
          </div>
          <div className="pck-map">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withMountEvent(Container, 'Container');