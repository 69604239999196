import { useState, useEffect } from "react";
import { RequiredMark } from "../RequiredMark";
import { useFormInput } from "../../../utils/InputUtils";
import { rexEngNumSp } from "../../../constants/RegularEpressions";
import { TextField } from "@material-ui/core";
import ValidateMark from "../ValidateMark";
import Grid from "@mui/material/Grid";

export const ValidatePassword = (props) => {
  const password1 = useFormInput("", { password: true });
  const password2 = useFormInput("", { password: true });
  const [validPwd, setValidPwd] = useState();
  const [validConfPwd, setValidConPwd] = useState();
  let profile = props.profile;

  useEffect(() => {
    if (password1.value !== "") {
      setValidPwd(rexEngNumSp.test(password1.value));
      if (
        password2.value === password1.value &&
        rexEngNumSp.test(password1.value)
      ) {
        setValidConPwd(true);
        profile.password = password1.value;
        props.setProfile(profile);
      } else {
        setValidConPwd(false);
      }
    } else if (password1.value === "" && password2.value === "") {
      setValidPwd();
      setValidConPwd();
    } else {
      setValidPwd();
    }
  }, [props, password1, password2]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <h1>
          <RequiredMark /> 비밀번호
        </h1>
      </Grid>
      <Grid item xs={11} md={4}>
        <TextField
          fullWidth
          size="small"
          type="password"
          className="signup-text-input"
          variant={"outlined"}
          {...password1}
          helperText={"*띄어쓰기 없는 영문, 숫자, 특수문자를 포함한 8~15자"}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        {validPwd !== undefined ? (
          <ValidateMark offset={{ x: 40, y: 20 }} valid={validPwd} />
        ) : null}
      </Grid>
      <Grid xs={12}></Grid>
      <Grid item xs={12} md={2}>
        <h1>
          <RequiredMark /> 비밀번호 확인
        </h1>
      </Grid>
      <Grid item xs={11} md={4}>
        <TextField
          type="password"
          size="small"
          fullWidth
          className="signup-text-input"
          {...password2}
          variant={"outlined"}
        />
      </Grid>
      <Grid item xs={1} md={1}>
        {validConfPwd !== undefined ? (
          <ValidateMark offset={{ x: 40, y: 20 }} valid={validConfPwd} />
        ) : null}
      </Grid>
    </Grid>
  );
};
