import { LinearProgress} from "@material-ui/core";

const style = {
    position: 'fixed',
    top: 0,
    width: '100%'
}

export const Loading = () => {
    return (
        <LinearProgress color='primary' style={style} />
    )
}