import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormInput } from "../../utils/InputUtils";
import { inject } from "mobx-react";
import {
  IconButton,
  OutlinedInput,
  TextField,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";

import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import {
  // FacebookLoginButton,
  GoogleLoginButton,
  KakaoLoginButton,
  NaverLoginButton,
  // AppleLoginButton,
} from "../../components/utilities/SocialLoginButtons";

import InputAdornment from "@material-ui/core/InputAdornment";

import "../../css/authentication/signin.css";
import { Loading } from "../../components/utilities/Loading";
import withMountEvent from "../../components/withMountEvent";
import { AlertContext } from "../../contexts/AlertContext";

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [isLockedSubmit, setIsLockedSubmit] = useState(false);
  // const [ saveId, setSaveId ] = useState(false);
  // const [ autoLogin, setAutoLogin ] = useState(false);

  const username = useFormInput("", { only_eng: true });
  const password = useFormInput("", { password: true });
  const history = useHistory();
  const setAlert = useContext(AlertContext).setAlert;

  const release = (callback) => {
    setLoading(false);
    setIsLockedSubmit(false);
    typeof callback === "function" && callback();
  };

  function loginClickHandler() {
    if (isLockedSubmit) {
      return;
    }

    if (!username.value || !password.value) {
      setAlert({
        type: "error",
        message: "Please Enter username and password!",
      });

      return;
    }

    setLoading(true);
    setIsLockedSubmit(true);
    props.sessionStore
      .signIn(username.value, password.value, true)
      .then(() => {
        try {
          props.sessionStore.fetchMe();
        } catch (e) {
          console.error("Login Error", e);
        }
        setLoading(false);
        setAlert({
          type: "success",
          message: "Login Succesfull!",
        });

        setTimeout(() => {
          history.push("population");
        }, 1000);
      })
      .catch((e) => {
        setAlert({
          type: "error",
          message: "*가입하지 않은 아이디거나, 잘못된 비밀번호입니다!",
        });
        switch (e) {
          case "TOKEN_NOT_EXISTS": {
            release({ error: "로그인 실패(T404)" });
            break;
          }
          default: {
            release({ error: "로그인 실패" });
            break;
          }
        }
      });
  }

  function enterKeyPressHandler(e) {
    if (e.key === "Enter") {
      loginClickHandler();
    }
  }
  // function autoLoginToggle() {
  //     setAutoLogin(!autoLogin);
  // }
  // function saveIdtoggle() {
  //     setSaveId(!saveId);
  // }

  const pwdShowHandler = () => {
    setShowPwd(!showPwd);
  };

  return (
    <Container maxWidth="md">
      {loading && <Loading />}
      <Grid
        container
        style={{ height: "100vh", padding: "0 1.5rem" }}
        alignItems="center"
        justifyContent="center"
        columnSpacing={{ xs: 0, md: 6 }}
      >
        <Grid item md={6}>
          <div className="native-signin">
            <p className="header">일반 로그인</p>
            <TextField
              type={"text"}
              label={"Enter Username"}
              {...username}
              variant={"outlined"}
              className="signin-input"
            />
            <FormControl variant="outlined" className="signin-input">
              <InputLabel htmlFor="input-password">Password</InputLabel>
              <OutlinedInput
                id="input-password"
                type={showPwd ? "text" : "password"}
                label={"Enter password"}
                {...password}
                onKeyPress={enterKeyPressHandler}
                endAdornment={
                  <InputAdornment position={"end"}>
                    <IconButton onClick={pwdShowHandler} edge={"end"}>
                      {showPwd ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <div className="save-credentials">
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" />}
                label="아이디 저장"
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" />}
                label="자동 로그인"
                labelPlacement="end"
              />
            </div>
            <hr />
            <div className="credentials-restore">
              <Button size="small">
                <Link to="/find-id">아이디 찾기</Link>
              </Button>
              <Button size="small">
                <Link to="/find-password">비밀번호 찾기</Link>
              </Button>
              <Button size="small">
                <Link to="/sign-up">회원가입</Link>
              </Button>
            </div>
            <hr />
            <Button
              disabled={isLockedSubmit}
              onClick={loginClickHandler}
              className="signin-btn"
              variant="outlined"
            >
              로그인
            </Button>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="foreign-signin">
            <p className="header">간편 로그인</p>
            <div className="social-btn-group">
              <KakaoLoginButton />
              <NaverLoginButton />
              <GoogleLoginButton />
            </div>
            {/* <FacebookLoginButton />
            <AppleLoginButton /> */}
          </div>
        </Grid>
      </Grid>

      {/* <section className="signin-wrapper"></section> */}
    </Container>
    // <main className="signin-container">

    // </main>
  );
};

export default withMountEvent(inject(["sessionStore"])(SignIn), "Sign-in");
