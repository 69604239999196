import {Company} from "./Comapny";
import {Organization} from "./Organization";

export class User {
    uuid: string;
    id: number;
    created_at: number;
    updated_at: number;
    deleted_at: number;
    organization_uuid: string;
    company_uuid: string;
    type: string;
    username: string;
    should_change_password: boolean;

    // JOIN
    organization: Organization;
    company: Company;
    permissions: Object;

    constructor(user: User) {
      Object.assign(this, user);
      if (user?.company) this.company = new Company(user?.company);
      if (user?.organization) this.organization = new Organization(user?.organization);
    }

    get typeAlias(): string {
      // TODO
      return null;
    }
}