import { Switch } from "react-router-dom";
import PermissionRoute from "./PermissionRoute";

import MyProfile from "../components/platform/functions/functionthree/account/Profile";
import AgencyUserList from "../components/platform/functions/functionthree/account/AgencyUserList";
import MasterAuthorization from "../components/platform/functions/functionthree/account/MasterAuthorization";
import TerminateGuidance from '../components/platform/functions/functionthree/account/TerminateGuidance';
import TerminateCheckPwd from "../components/platform/functions/functionthree/account/TerminateCheckPwd";
import TerminateComplete from "../components/platform/functions/functionthree/account/TerminateComplete";
import PlacesList from '../components/platform/functions/functionthree/analysis/AnalysisPlacesList';
import PlaceRegister from '../components/platform/functions/functionthree/analysis/PlaceRegister';

import NotFound from '../components/platform/functions/functionthree/TempNotFound';

const REDIRECT_MAIN = '/';

const ProfileSwitch = ({match}) => (
    <Switch>
        {/* 마이페이지 하위 메뉴 라우트*/}
        <PermissionRoute title={'마이 페이지 | 내 프로필'} path={`${match.url}`} exact component={MyProfile} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 소속기관 계정 목록'} path={`${match.url}/agency-user-list`} exact component={AgencyUserList} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 소속기관 계정 목록'} path={`${match.url}/agency-user-list/master_authorization`} component={MasterAuthorization} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 회원 탈퇴'} path={`${match.url}/terminate`} exact component={TerminateGuidance} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 회원 탈퇴'} path={`${match.url}/terminate/check-pwd`} component={TerminateCheckPwd} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 회원 탈퇴'} path={`${match.url}/terminate/complete`} component={TerminateComplete} authorized redirect={REDIRECT_MAIN}/>

        <PermissionRoute title={'마이 페이지 | 분석 장소 목록'} path={`${match.url}/analysis-places-list`} component={PlacesList} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 신규 장소 등록'} path={`${match.url}/enroll-new-place`} component={PlaceRegister} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 공유 요청'} path={`${match.url}/request-share`} component={NotFound} authorized redirect={REDIRECT_MAIN}/>

        <PermissionRoute title={'마이 페이지 | FAQ 및 서비스 이용 문의'} path={`${match.url}/f_a_q`} component={NotFound} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 앱 설치 링크 안내'} path={`${match.url}/app-download`} component={NotFound} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 이용 매뉴얼 다운로드'} path={`${match.url}/manual-download`} component={NotFound} authorized redirect={REDIRECT_MAIN}/>
        <PermissionRoute title={'마이 페이지 | 이용약관'} path={`${match.url}/terms-a-conditions`} component={NotFound} authorized redirect={REDIRECT_MAIN}/>
    </Switch>
);

export default ProfileSwitch;