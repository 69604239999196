import React, { useEffect, useState } from "react";
import { MapContainer, Marker } from "react-leaflet";
import ZoomListener from "../../components/platform/functions/functionone/ZoomListener";
import PlacesList from "../../components/platform/functions/functionone/PlacesList";
import ChartView from "../../components/platform/functions/functionone/chartview/ChartView";
import RTRanking from "../../components/platform/functions/functionone/RTRanking";
import MapTool from "../../components/platform/functions/functionone/MapTool";
// import SpotMarkers from '../../components/platform/functions/functionone/map/SpotMarkers';
import LineLayer from "../../components/platform/functions/functionone/map/LineLayer";
import Container from "../../components/platform/Container";
import withMountEvent from "../../components/withMountEvent";

import "animate.css";
import "../../css/platform/functions/function-one.css";
import * as L from "leaflet";

const koreaBounds = [
  [45, 148],
  [28, 105],
];

const Population = () => {
  const [spotData, setSpotData] = useState();
  const [lineData, setLineData] = useState();
  const [map, setMap] = useState(null);

  if (spotData) console.log();

  useEffect(() => {
    if (!map) return;
    map.locate();
    map.on("locationfound", (e) => {
      map.flyTo(e.latlng, 17);
    });
  }, [map]);

  return (
    <Container>
      <MapContainer
        center={[35.17098218799382, 129.1270673274994]}
        zoom={16}
        zoomControl={false}
        zoomSnap={0.5}
        scrollWheelZoom={true}
        zoomAnimation={true}
        maxBounds={koreaBounds}
        whenCreated={setMap}
        id="map"
      >
        <ZoomListener />
        <MapTool />
        {/*<SpotMarkers data={spotData}/>*/}
        <LineLayer data={lineData} />
        <Marker
          position={[35.172812929631704, 129.12573121341853]}
          icon={L.divIcon({
            //html: `I'm Here`,
            html: `영화의전당`,
            className: "im_here_btn",
            iconSize: L.point(80, 30),
            iconAnchor: [40, 30],
          })}
        />
      </MapContainer>
      <RTRanking />
      <ChartView />
      <PlacesList setLineData={setLineData} setSpotData={setSpotData} />
    </Container>
  );
};

export default withMountEvent(Population, "Population");
