import React from "react";

export default function withMountEvent(InputComponent, componentName) {
  return class OutputComponent extends React.Component {
    componentDidMount() {
      console.info(componentName, "is Mounted");
    }

    render() {
      return <InputComponent {...this.props} />;
    }
  };
}
