import Profile from "../../../../../screens/platform/Profile";
import React from 'react';
import {inject} from "mobx-react";
import { RiVipCrownFill } from "react-icons/ri";
import {Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@material-ui/core";

import { Box, Modal, Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    bgcolor: 'background.paper',
    border: '1px solid black',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',

};

class AnalysisPlacesList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isDesc: false,
            agency: "BIFF(영화의전당)",
            numberSensors: 8,
            temp: this.places,
            isOpen: false,
        }
    }

    places = [{
        "BIFF": [
            {space: "야외광장", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: true, isWorked: false},
            {space: "비프힐", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: true, isWorked: true},
            {space: "더블콘 입구", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: false, isWorked: true},
            {space: "더블콘 2층", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: true, isWorked: false},
            {space: "오디토리움", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: false, isWorked: false},
        ]},
        {"수변공원":[
            {space: "더블콘 2층", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: true, isWorked: true},
            {space: "오디토리움", installer: "easyun46", installedAt: "2021-07-09", isMaster: true, isShared: true, isWorked: true},
        ]
    }]

    rows() {
        let tableRows = [];
        this.state.temp?.length !== 0 &&
        this.state.temp.forEach((items, index) => {
            let placeName = Object.keys(items).toString();
            let size = items[placeName].length;
            items[Object.keys(items).toString()].forEach((obj, i) => {
                tableRows.push(
                    <TableRow key={Object.keys(items).toString() + obj.space}>
                        {i === 0 ?<TableCell align="center" rowSpan={size}>{placeName}</TableCell>: null}
                        <TableCell align="center" >{obj.space}</TableCell>
                        <TableCell align="center" ><span style={{position: "relative"}} className="icon-wrapper"><RiVipCrownFill size={20} color="black" className="master-icon"/>{obj.installer}</span></TableCell>
                        <TableCell align="center" >{obj.installedAt}</TableCell>
                        <TableCell align="center" style={{color: obj.isShared ? '#ccc' : 'blue', cursor: 'pointer'}} onClick={() => this.setState({isOpen: true})}>공유중</TableCell>
                        <TableCell align="center" ><span className={obj.isWorked ? "r_green" : "r_red"}/></TableCell>
                    </TableRow>
                )
            })
        })
        return tableRows;
    }

    searchPlace = (e) => {
        let temp = [];
        let temp_in = [];
        let key = ''
        this.places.forEach((items, index )=> {
            key = Object.keys(items).toString();
            temp_in = [];
            if(key.includes(e.target.value)){
                temp.push(items);
            }else {
                items[Object.keys(items).toString()].forEach((obj, i) => {
                    if(obj.space.includes(e.target.value)){
                        temp_in.push(obj);
                    }
                });
                temp.push({[key]: temp_in});
            }
        })
        this.setState({temp: temp});
    }

    render() {
        // this.props.sessionStore.fetchMe();
        return (
            <Profile>
                <div className="c_wrapper" style={{width: 1500}}>
                    <div className="header">분석 장소 목록</div>
                    <div className="sub_title">
                        현재 <span className="txt_bold">{this.state.agency}님 소속기관이 </span>등록한 공간은 총 <span className="txt_bold">{this.state.numberSensors}곳</span> 입니다.
                    </div>
                    <div style={{width: 1400, margin: "20px 0", textAlign: "right"}}>
                        <TextField onChange={this.searchPlace} id="outlined-basic" autoComplete="off" label="장소/공간을 검색해보세요!" variant="outlined" size="small" style={{ width: 500 }} />
                    </div>
                    <div style={{height: 550, width: 1400, overflow: "auto" }}>
                        <Table stickyHeader aria-label="sticky table" style={{width: "100%"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ width: 300 }}>장소</TableCell>
                                    <TableCell align="center" style={{ width: 250 }}>공간</TableCell>
                                    <TableCell align="center" style={{ width: 250 }}>설치자</TableCell>
                                    <TableCell align="center" style={{ width: 200, cursor: "pointer" }} onClick={() => {this.setState({isDesc: !this.state.isDesc})}}>설치일자 {this.state.isDesc ? "▼" : "▲"}</TableCell>
                                    <TableCell align="center" style={{ width: 200 }}>공유상태</TableCell>
                                    <TableCell align="center" style={{ width: 200 }}>작동상태</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { this.rows() }
                            </TableBody>
                        </Table>
                    </div>
                </div>

                <Modal
                    open={this.state.isOpen}
                    onClose={() => this.setState({isOpen: false})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <span className={"temp-close-btn"} style={{position: 'absolute', fontSize: 30, fontWeight:'bold', right: 10, top: 10, cursor: 'pointer', outline: 'none'}} onClick={() => this.setState({isOpen: false})}>&times;</span>
                        <div style={{fontSize: 20, lineHeight: 15}}>
                            공유상태 페이지 입니다
                        </div>
                    </Box>
                </Modal>
            </Profile>
        )
    }
}

export default inject(['sessionStore'])(AnalysisPlacesList);