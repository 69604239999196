import { useState, useEffect, useContext } from "react";
import { useFormInput } from "../../../utils/InputUtils";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import "../../../css/authentication/find-id.css";
import { AlertContext } from "../../../contexts/AlertContext";

const FindIdEmail = (props) => {
  const emailHead = useFormInput("");
  const emailBody = useFormInput("");
  const [emailType, setEmailType] = useState("easy");
  const [emailTail, setEmailTail] = useState("gmail.com");
  const setAlert = useContext(AlertContext).setAlert;
  let profile = props.profile;

  const emailTailChangeHandler = (e) => {
    setEmailTail(e.target.value);
  };

  const onClick = () => {
    if (emailTail !== "other") {
      if (emailHead.value === "") {
        setAlert({
          type: "error",
          message: "Please enter Email",
        });
        return;
      }
    } else {
      if (emailHead.value === "" || emailBody.value === "") {
        setAlert({
          type: "error",
          message: "Please enter Email",
        });
        return;
      }
    }
    if (props.timer) {
      props.setTimer(false);
      setTimeout(() => {
        props.setTimer(true);
      }, 1000);
    } else {
      props.setTimer(true);
    }

    setAlert({
      type: "success",
      message:
        "We have sent you authentication key via email, Please enter and continue!",
    });
  };

  const onChange = (e) => {
    setEmailType(e.target.value);
  };

  useEffect(() => {
    let email = "";
    if (emailType === "easy") {
      email = emailHead.value + "@" + emailTail;
    } else {
      email = emailHead.value + "@" + emailBody.value;
    }

    profile.email = email;
    props.setProfile(profile);
  });

  return (
    <Grid container>
      <Grid item md={1} xs={12}>
        <h1>이메일</h1>
      </Grid>
      <Grid item md={11} xs={12}>
        <Grid container>
          <Grid item>
            <RadioGroup
              className="find-id-radio-wrapper"
              row
              aria-label="position"
              name="email-type"
              onChange={onChange}
              value={emailType}
            >
              <FormControlLabel
                value="easy"
                control={<Radio color="primary" />}
                label="선택"
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="직접입력"
              />
            </RadioGroup>
          </Grid>
          {/* <div className="email-inputs"> */}
          <Grid container>
            <Grid item md={12}>
              <div className="email-box">
                <TextField
                  size="small"
                  id="user-email-head"
                  {...emailHead}
                  variant="outlined"
                  className={"signup-input"}
                />{" "}
                <span className="email-sign"> @ </span>
                {emailType === "custom" ? (
                  <TextField
                    size="small"
                    id="user-email-footer"
                    {...emailBody}
                    variant="outlined"
                    className={"email-inputs"}
                  />
                ) : (
                  <select
                    className={"email-select"}
                    defaultValue={emailTail}
                    onChange={emailTailChangeHandler}
                  >
                    <option value="naver.com">naver.com</option>
                    <option value="gmail.com">gmail.com</option>
                  </select>
                )}
                {props.submitButton ? (
                  <Button onClick={onClick} variant={"contained"}>
                    인증
                  </Button>
                ) : null}
              </div>
            </Grid>
            <Grid item md={12}>
              <label className="email-helptext">
                *이메일 주소를 입력하시면 더 많은 정보를 받아보실 수 있어요!
              </label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FindIdEmail;
