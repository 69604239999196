
import Router from './routes/index';
import './App.css';
import stores from './stores';
import { api_pcp } from './api/api_pcp';
import { Provider } from "mobx-react";
import { StorageUtils } from './utils/StorageUtils';
import { CustomAlert } from './components/utilities/CustomAlert';
import { useState } from 'react';
import { AlertContext } from './contexts/AlertContext';

// Load Authorization token
api_pcp.setHeader('Authorization', StorageUtils.getAuthorization());

function App() {
  const [ alert, setAlert ] = useState({});
  const alertControler = {
    setAlert: setAlert
  }

  return (
    <Provider {...stores}>
      <CustomAlert alert={alert} />
      <AlertContext.Provider value={alertControler} >
        <Router />
      </AlertContext.Provider>
    </Provider>
  );
}

export default App;