import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../screens/auth/SignIn';
import SignUp from '../screens/auth/SignUp'
import FindId from "../screens/auth/FindId";
import FindPassword from "../screens/auth/FIndPassword";
import PermissionRoute from "./PermissionRoute";
import NotFoundScreen from "../screens/NotFoundScreen";
import Population from "../screens/platform/Population";
import DataAnalyze from "../screens/platform/DataAnalyze";
import ProfileSwitch from "./ProfileSwitch";


const REDIRECT_MAIN = '/';
const REDIRECT_SIGN_IN = '/sign-in';

const authorizedOnly = {
  authorized: true,
  redirect: REDIRECT_SIGN_IN,
};

const unauthorizedOnly = {
  unauthorized: true,
  redirect: REDIRECT_MAIN,
};

class Router extends React.Component{
  render (){
    return (
      <BrowserRouter>
        <ScrollToTop/>

        <Switch>
          <Route exact path='/'>
            <Redirect to={'/population'}/>
          </Route>

          <PermissionRoute title={'유동인구'} path={'/population'} component={Population} {...authorizedOnly}/>
          <PermissionRoute title={'데이터 분석'} path={'/data-analyze'} component={DataAnalyze} {...authorizedOnly}/>

          <PermissionRoute title={'로그인'} path={`/sign-in`} component={SignIn} {...unauthorizedOnly}/>
          <PermissionRoute title={'회원가입'} path={`/sign-up`} component={SignUp} {...unauthorizedOnly}/>
          <PermissionRoute title={'아이디 찾기'} path={'/find-id'} component={FindId} {...unauthorizedOnly}/>
          <PermissionRoute title={'비밀번호 찾기'} path={'/find-password'} component={FindPassword} {...unauthorizedOnly}/>
          {/*<PermissionRoute title={'로그아웃'} path={`/sign-out`} component={SignOutScreen} {...authorizedOnly}/>*/}

          <Route path={'/profile'} component={ProfileSwitch}  unauthorized authorized/>
          <Route path={'/404'} component={NotFoundScreen} />
          <Route component={NotFoundScreen}/>
        </Switch>
      </BrowserRouter>
    );
  }
}



export default Router;