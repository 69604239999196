import React from 'react';
import Profile from "../../../../screens/platform/Profile";

export default class TempNotFound extends React.Component{

    render() {
        return (
            <Profile>
                <div> page not found </div>
            </Profile>
        )
    }
}