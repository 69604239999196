import { useEffect, useState } from "react/cjs/react.development"

const Timer = ( props ) => {
    const [timer, setTimer] = useState(props.seconds);
    const [time, setTime] = useState("");
    useEffect(() => {
        setTimer(props.seconds);
    },[ props.seconds ]);

    useEffect(() => {
        let n = setTimeout(() => {
            setTimer(timer-1)
        }, 1000);
        if( timer <= 0){
            alert("if you did not get message Try again");
            clearTimeout(n)
        }

        let minutes = Math.floor(timer / 60);
        let seconds = timer % 60;
        let timeString = "";
        if (seconds < 10) {
            seconds = "0" + String(seconds);
        }
        timeString = minutes + ":" + seconds;
        setTime(timeString)
    }, [timer])

    return (
        <span className={props.className? props.className:null}>{time}</span>
    )
}

export default Timer;