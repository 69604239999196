import React from 'react';
import Profile from "../../../../../screens/platform/Profile";
import CompleteModal from "../../../../../modals/CompleteModal";
import {Button, TextField} from "@material-ui/core";

export default class MasterAuthorization extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isOpen : false
        }
    }

    btnAuthorization = () => {
        this.setState({isOpen: true})
    }

    render() {
        return (
            <Profile>
                <div className="c_wrapper" style={{width: 1000}}>
                    <div className="header">소속기관 계정 목록</div>
                    <div className="sub_title">
                        정말로 <span className="txt_bold">{this.props.location.obj.username}</span> 님에게 마스터 권한을 부여하시겠습니까?
                    </div>
                    <div style={{marginTop: 30}}>마스터 계정 권한을 부여하게 되면 아래와 같은 권한들을 소유하게 됩니다!</div>

                    <div style={{marginTop: 30}}>
                        ① 센서 데이터 권한 공유 요청 알림 수신 및 승인 <br/>
                        ② 타 소속기관에 자체적 센서 데이터 공유 <br/>
                        ③ 같은 소속기관 내 마스터 계정 권한 부여 <br/>
                    </div>
                    <div style={{marginTop: 30}}> 마스터 계정 권한 부여를 진행하시려면 본인인증을 위해 비밀번호를 입력하여 주십시오.</div>
                    <TextField id="outlined-basic" label="비밀번호 입력" variant="outlined" type="password" autoComplete="off" style={{width: 350, marginTop: 30, display: "flex"}}/>
                    <Button variant="outlined" onClick={this.btnAuthorization} style={{width: 200, float:"right"}}>마스터 권한 부여</Button>
                </div>
                <CompleteModal open={ this.state.isOpen } close={ () => this.setState({isOpen: false}) } isAuth = {true}>
                    성공적으로 권한을 부여했습니다
                </CompleteModal>
            </Profile>
        )
    }
}