import { useContext, useState } from "react";
import { useFormInput } from "../utils/InputUtils";
import { api_pcp } from "../api/api_pcp";
import { AlertContext } from "../contexts/AlertContext";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Button, Container, Dialog, TextField } from "@material-ui/core";
import Grid from "@mui/material/Grid";

import "../css/authentication/member-info.css";
import { CustomAlert } from "../components/utilities/CustomAlert";

export const CompanySelectModal = (props) => {
  const searchTerm = useFormInput("", { onPressEnter: updateCompany });
  const [company, setCompany] = useState([]);
  const [companyUUID, setCompanyUUID] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isLockedSubmit, setIsLockedSubmit] = useState(false);
  const setAlert = useContext(AlertContext).setAlert;
  const open = props.open;
  const setOpen = props.setOpen;

  function updateCompany() {
    const query = searchTerm.value;
    if (!query) {
      setAlert({
        type: "error",
        message: "Please enter search query",
      });
      setIsLockedSubmit(false);
      return;
    }

    if (!isLockedSubmit) {
      setIsLockedSubmit(true);
      api_pcp.get("/v1/company", { q: query }).then((res) => {
        setIsLockedSubmit(false);
        if (!res.ok) {
          return;
        }
        if (res.data) {
          const { companies } = res.data;
          setCompany(companies);
        }
      });
    }
  }

  function submitClickHandler() {
    props.setName(selectedCompany);
    props.setUUID(companyUUID);
    handleClose();
  }

  function companySelectHandler(e) {
    const values = e.target.value.split("$");
    setSelectedCompany(values[0]);
    setCompanyUUID(values[1]);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const companyList = company.map((obj, i) => {
    return (
      <div className="company-item" key={"company-" + i}>
        <input
          type="radio"
          id={"company-item-" + i}
          value={obj.name + "$" + obj.uuid}
          name="company-item"
        />
        <label htmlFor={"company-item-" + i}>
          <h4>{obj.name}</h4>
          <p>
            업종 : {obj.business_type} ㅣ 기업규모 : {obj.business_scale}
          </p>
          <p>{obj.address}</p>
        </label>
      </div>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="id-validation-dialog"
    >
      <CustomAlert alert={alert} autoCloseAfter={5000} />
      <Container maxWidth="sm">
        <Grid container spacing={1} className="org-modal-container">
          <Grid item xs={12}>
            <h3 className="info-header">
              사업자등록번호/법인등록번호/기업명을 입력해주세요
            </h3>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                type="text"
                helperText={"*등록번호는 (-)를 제외하고 입력해주세요."}
                size="small"
                {...searchTerm}
                className={"signup-text-input"}
                variant={"outlined"}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={updateCompany}
                className="signup-btn"
                variant={"contained"}
              >
                검색
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="info-header">이 정보가 맞나요?</h3>
          </Grid>
          <Grid item xs={12}>
            <div className="org-list-wrapper">
              <div className="org-list-body" onChange={companySelectHandler}>
                {companyList.length > 0 ? (
                  companyList
                ) : (
                  <div className="org-empty-list">
                    <span>
                      검색하신 내용이 없습니다! <br />
                      기업 이름을 다시 한번 확인해주세요.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={6}>
              <Button
                onClick={handleClose}
                className="signup-btn"
                variant={"outlined"}
                fullWidth
              >
                닫기
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={submitClickHandler}
                className="signup-btn"
                variant={"contained"}
              >
                확인 !
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};
