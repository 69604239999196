import React from 'react';
import Chart from "react-apexcharts";

/*
  Hourly floation population chart for week.
  요일별 시간대별 유동인구 꺾은선 그래프
*/

class BarChartDaily extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          data: [400, 430, 448, 470, 540, 580, 690]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 200
          },
          colors:["#0d3242"]
          ,
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['월', '화', '수', '목', '금', '토', '일'],
          }
        },
      };
    }

    render() {
      return (
        <Chart options={this.state.options} series={this.state.series} type="bar" height={200} />
      );
    }
  }

  export default BarChartDaily;