const ValidateMark = (props) => {
  const style = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginTop: "15px",
    backgroundColor: props.valid ? "#00ff00" : "#ff0000",
    // position: "absolute",
    // right: "40px",
    // top: "20px",
    right: String(props.offset.x) + "px",
    top: String(props.offset.y) + "px",
  };

  return <div style={style}> </div>;
};

export default ValidateMark;
