import { Button, Container } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import userIcon from "../resources/authentication/user-icon.png";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  userIcon: {
    marginTop: "40px",
    width: "150px",
  },
  modalSubmitBtn: {
    borderRadius: "20px",
    height: "43px",
    backgroundColor: "#c4c4c4",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  },
}));

const messages = {
  empty: "아이디를 확인해주세요",
  taken: "이미 등록된 아이디입니다.\n" + "확인 후 다시 입력해주세요.",
  available: "사용 가능한 아이디입니다.",
};

export const IdValidationModal = (props) => {
  const msg = props.msg;
  const open = props.open;
  const setOpen = props.setOpen;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="id-validation-dialog"
    >
      <MuiDialogTitle></MuiDialogTitle>
      <MuiDialogContent>
        <Container maxWidth="sm">
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            spacing={3}
            padding={"2rem"}
          >
            <Grid item xs={12} container justifyContent={"center"}>
              <img
                src={userIcon}
                alt="user-icon"
                className={classes.userIcon}
              />
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <h3>{messages[msg]}</h3>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                fullWidth
                autoFocus
                onClick={handleClose}
                className={classes.modalSubmitBtn}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MuiDialogContent>
    </Dialog>
  );
};
