import { IconButton, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';

export const CustomAlert = (props) => {
    const [ open, setOpen ] = useState(false);
    const alert = props.alert;

    const closeHandler = () => {
        setOpen(false);
    }

    useEffect(() => {
        if(Object.keys(alert).length !== 0){
            setOpen(true);
        };
    }, [alert])

    return (
        
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            open={open} onClose={closeHandler}
            autoHideDuration={3000}
            style={{zIndex: 99999}}
        >
            <MuiAlert 
                className='alert' 
                elevation={6} 
                variant='filled' 
                severity={alert.type} 
                action={
                    <IconButton 
                        onClick={closeHandler}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            >
                {alert.message}
            </MuiAlert>
        </Snackbar>
        
        );
} 

