import React from 'react';
import { FiCheckCircle } from "react-icons/fi";
import {Link} from "react-router-dom";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import '../css/platform/functions/functionthree/modal.css';

const styles = {
    complete_wrapper: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center"
    },
    complete_txt: {
        fontSize: 30,
        fontWeight: "bold",
        width: 500,
        marginTop: 20
    }
};

export default class CompleteModal extends React.Component {

    closeModal = () => {
        this.props.close(false);
        if(!this.props.isAuth)
            this.props.parentClose();
    }

    render() {
        const { open, size } = this.props;
        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                    <DialogContent className={size === "small" ? "s-modal" : "c-modal"}>
                        <button className="close-btn" onClick={this.closeModal}> &times; </button>
                        <div style={styles.complete_wrapper}>
                            <FiCheckCircle size={200}/>
                            <div style={styles.complete_txt}>{this.props.children}</div>
                            {this.props.isAuth ?
                                <Link to="/profile/agency-user-list">
                                    <Button variant="outlined" onClick={this.closeModal} style={{width: 200, marginTop: 30}}>확인</Button>
                                </Link>
                                :
                                <Button variant="outlined" onClick={this.closeModal} style={{width: 200, marginTop: 30}}>확인</Button>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}