import React, {useEffect, useState} from 'react';
import {inject} from 'mobx-react';
import {api_pcp} from '../../api/api_pcp';
import logo from '../../resources/logo.png';
import '../../css/platform/platform-navbar.css'
import {useHistory} from 'react-router-dom';
import {Avatar, ListItemIcon, MenuItem, MenuList, Typography} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const PlatformNavbar = (props) => {
    const history  = useHistory();
    const [user, setUser] = useState(props.sessionStore.user);

    useEffect(() => {
        setUser(props.sessionStore.user);
    }, [props])

    const signOutClickHandler = () => {
        try{
            api_pcp.delete('/v1/auth/session').then((r) => {
                console.log(props)
                api_pcp.deleteHeader('Authorization');

                try {
                    typeof props.sessionStore?.reset === 'function' && props.sessionStore?.reset();
                } catch (e) {}

                try {
                    sessionStorage.removeItem('Authorization');
                    localStorage.removeItem('Authorization');
                } catch (e) { console.warn(e) }

                history.push('/sign-in');

            })
        } catch (e) {
            console.error(e)
            history.push('/');
        }

    }
    return(
        <div className="pck-navbar">
            <div className="pck-logo">
                <img src={logo} height={17} alt={'pcp-logo'} />
            </div>
            <div className="pck-user-container">
                <div className="pck-user">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <p style={{fontSize: 14}}>{user?.username?.toUpperCase()}</p>
                        <p style={{fontSize: 12, fontWeight: 'normal'}}>부산광역시 교통정책과</p>
                    </div>
                    <div style={{width: 12}}/>
                    <Avatar style={{}} alt={user?.username} src="https://material-ui.com/static/images/avatar/1.jpg" />

                    <div className="pck-dropdown-wrapper">
                        <Paper>
                            <MenuList>
                                <MenuItem onClick={signOutClickHandler}>
                                    <ListItemIcon>
                                        <ExitToAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">로그아웃</Typography>
                                </MenuItem>
                            </MenuList>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default inject(['sessionStore'])(PlatformNavbar);