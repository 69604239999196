import { Button, Dialog } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react/cjs/react.development";
import { MdPersonOutline, MdCreate } from "react-icons/md";
import "../css/modals/modal.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

const UserListModal = (props) => {
  // const [ open, setOpen ] = useState(true);
  const [userList, setUserList] = useState(props.list);
  const handleClose = () => {
    // setOpen(false);
  };

  console.log(userList);
  useEffect(() => {
    setUserList(props.list);
  }, [props]);

  const getDate = (n) => {
    console.log(n);
    return new Date(Number(n)).toDateString();
  };

  const listArray = userList.map((obj, i) => {
    return (
      <li key={"user-list-" + i}>
        <p>
          <MdPersonOutline /> <b>{obj.username}</b>
        </p>
        <span>
          <MdCreate /> {getDate(obj.created_at)}
        </span>
      </li>
    );
  });

  return (
    <Dialog open={true} onClose={handleClose}>
      <Grid container>
        <div className="user-list-modal">
          <h1 className="user-list-header">User List</h1>
          <ul className="">{listArray}</ul>
          <div className="login-wrapper">
            <Button variant="outlined">
              <Link to="sign-in">Login Page</Link>
            </Button>
          </div>
          <div className="help-links">
            <span>
              Forgot password? <Link to="find-password">Reset password</Link>
            </span>
            <span>
              Dont have account? <Link to="sign-up">Sign up</Link>
            </span>
          </div>
        </div>
      </Grid>
    </Dialog>
  );
};

export default UserListModal;
