import React from "react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import {BiUserCircle} from "react-icons/bi";
import {RiVipCrownFill} from "react-icons/ri";
import moment from "moment";
import {Link} from "react-router-dom";
import '../css/platform/functions/functionthree/modal.css';

const styles = {
    profile_wrapper: {
        textAlign: "center",
        width: "100%",
        marginTop: 30
    },
    profile_table: {
        width: "70%",
        fontSize: 20,
        margin: "auto",
    },
    profile_table_tr: {
        height: 60,
        lineHeight: "60px"
    },
    profile_table_ftd: {
        textAlign: "left"
    },
    profile_table_ltd: {
        textAlign: "right",
        fontWeight: "bold"
    }
};

export default class UserProfileModal extends React.Component{

    render() {
        const { open, close } = this.props;
        const user = this.props.userProfile;

        return (
            <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                <DialogContent className="s-modal">
                    <button className="close-btn" onClick={close}> &times; </button>
                        <div style={styles.profile_wrapper}>
                            <div>
                                <BiUserCircle size={200}/>
                            </div>
                            <table style={styles.profile_table}>
                                <tbody>
                                    <tr style={styles.profile_table_tr}><td style={styles.profile_table_ftd}>부서</td> <td style={styles.profile_table_ltd}>{user.department}</td></tr>
                                    <tr style={styles.profile_table_tr}><td style={styles.profile_table_ftd}>아이디</td> <td style={styles.profile_table_ltd}><span>{user.isMaster ? <RiVipCrownFill size={20} color="black" className="masterAccount"/> : null}&nbsp;&nbsp;&nbsp;{user.username}</span></td></tr>
                                    <tr style={styles.profile_table_tr}><td style={styles.profile_table_ftd}>가입일</td> <td style={styles.profile_table_ltd}>{moment.unix(user.created_at).format("YYYY-MM-DD")}</td></tr>
                                </tbody>
                            </table>
                            {
                                user.type === "SUPER_USER" ?
                                    <Button variant="outlined" disabled onClick={close} style={{width: 200, marginTop: 30}}>마스터 계정입니다</Button>
                                    :
                                    <Link to={{
                                        pathname: "./agency-user-list/master_authorization",
                                        obj:{
                                            username: user.username
                                        }
                                    }} userProfile={user}>
                                        <Button variant="outlined" onClick={close} style={{width: 200, marginTop: 30}}>마스터 권한 부여</Button>
                                    </Link>
                            }
                        </div>
                </DialogContent>
            </Dialog>
        )
    }
}