import React from 'react';
import {Button, Dialog, DialogContent} from "@material-ui/core";
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    modal: {
        width: '550px',
        height: '350px',
        background: "white",
        fontSize: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        flexDirection: "column"
    },
    txt_bold: {
        fontWeight: "bold"
    }
});

class NoTerminateModal extends React.Component{

    onClick = () => {
        this.props.history.push('../profile/agency-user-list');
    }

    render() {
        const { open } = this.props;
        const { classes } = this.props;

        return (
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogContent className={classes.modal}>
                    <div>
                        {this.props?.groupName}은 현재 마스터계정이 <br/>
                        회원님 하나로 회원탈퇴가 <span className={classes.txt_bold}>불가능</span>합니다.<br/><br/>

                        회원 탈퇴를 진행하시려면 소속기간 내<br/>
                        다른 이용자에게 권한을 부여해 주세요.
                    </div>
                    <Button variant="outlined" style={{width: 200, marginTop: 50}} onClick={this.onClick}>확인</Button>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(NoTerminateModal);