import { useState, useEffect } from "react";
import { useFormInput } from "../../../utils/InputUtils";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { RequiredMark } from "../RequiredMark";
import Grid from "@mui/material/Grid";

import "../../../css/authentication/find-id.css";

export const SignupEmail = (props) => {
  const [emailTail, setEmailTail] = useState("naver.com");
  const emailHead = useFormInput("");
  const emailBody = useFormInput("");
  const [emailType, setEmailType] = useState("easy");

  let profile = props.profile;

  const emailTailChangeHandler = (e) => {
    setEmailTail(e.target.value);
  };

  const onChange = (e) => {
    setEmailType(e.target.value);
  };

  useEffect(() => {
    let email = "";

    if (emailType === "easy") {
      if (emailHead.value) {
        email += emailHead.value + "@" + emailTail;
      }
    } else {
      if (
        emailHead.value &&
        emailBody.value &&
        emailBody.value.search(".") === 0
      ) {
        email += emailHead.value + "@" + emailBody.value;
      }
    }

    profile.email = email;
    props.setProfile(profile);
  }, [props, emailHead, emailBody, emailTail]);

  return (
    <Grid container>
      <Grid item md={2} xs={12}>
        <h1>
          <RequiredMark />
          이메일
        </h1>
      </Grid>
      <Grid item md={5} xs={12}>
        <RadioGroup
          className="find-id-radio-wrapper"
          row
          aria-label="position"
          name="email-type"
          onChange={onChange}
          value={emailType}
        >
          <FormControlLabel
            value="easy"
            control={<Radio color="primary" />}
            label="선택"
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="직접입력"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item md={2} xs={12}></Grid>
      <Grid item md={5} xs={12} container alignItems={"center"}>
        <Grid item xs={6}>
          <TextField
            size="small"
            id="user-email-head"
            {...emailHead}
            variant="outlined"
            className={"signup-input"}
          />{" "}
        </Grid>
        <Grid xs={1} container justifyContent={"center"}>
          <span> @ </span>
        </Grid>
        <Grid item xs={5}>
          {emailType === "custom" ? (
            <TextField
              size="small"
              id="user-email-footer"
              {...emailBody}
              variant="outlined"
              className={"email-inputs"}
            />
          ) : (
            <select
              className={"email-select"}
              defaultValue={emailTail}
              onChange={emailTailChangeHandler}
              style={{
                width: "100%",
                position: "relative",
                paddingLeft: ".5rem",
              }}
            >
              <option value="naver.com">naver.com</option>
              <option value="gmail.com">gmail.com</option>
            </select>
          )}
        </Grid>
        <Grid xs={12}>
          <label className="email-helptext">
            *이메일 주소를 입력하시면 더 많은 정보를 받아보실 수 있어요!
          </label>
        </Grid>
      </Grid>

      <Grid item md={10} xs={12}>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid container>
            <Grid item md={12}></Grid>
            <Grid item md={12}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
