import React, { useState } from 'react';
import classNames from 'classnames';
import { RiBookmarkLine,  RiBookmarkFill } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";
import { NavLink } from 'react-router-dom';

import LineChartToday from '../../charts/LineChartToday';
import RequestSensorModal from './RequestSensorModal';


function ChartItem(props){

    const [bookMark, setBookMark] = useState(false);
    const [modal, setModal] = useState(false);

    const sensor = [
        {id: 1, position: '더블콘', isShare: true}, 
        {id: 2, position:'야외광장', isShare: false}
    ];
    const [selectedSensor, setSelectedSensor] = useState(sensor[0]);

    function btnSensorHandler(key){
        setSelectedSensor(key);
    }


    return(
        <>
            <div className="item_wrapper">
                {props.isData ? 
                <>
                    <div className="chart_wrapper">
                        <span id="btnBookMark" onClick={() =>{setBookMark(!bookMark)}}> {bookMark ? < RiBookmarkFill size={40}/>: < RiBookmarkLine size={40}/>} </span>
                        
                        <div id="place_wrapper">
                            <div className="txt_gray">
                                오늘 조회 수 81회 
                                <span className="tooltips">?</span>
                            </div>
                            <div id="place_description">
                                <span id="place_name">영화의전당</span>
                                <span id="place_address">해운대구 수영강변대로 140</span>
                                <button id="btn_share" className={classNames("btn", selectedSensor.isShare ? '' : "btn_share" )} onClick={ () => selectedSensor.isShare ? null : setModal(true) }>{selectedSensor.isShare ? "공유하는 중" : "공유 요청하기"}</button>
                            </div>
                        </div>

                        <div className="place_pck_text">
                            <div>
                                지금 <span className="txt_bold">{selectedSensor.position}</span>에 <span className={classNames("txt_bold", selectedSensor.isShare ? '' : "blur")}>17명</span>의 방문객이 머물고 있습니다.
                            </div>
                            {sensor.map(key => 
                                <button key={'btn-' + key.id} className={classNames('btn', 'btn_sensor', key.id === selectedSensor.id ? 'btn_selected' : ' ')} onClick={() => btnSensorHandler(key)}>{key.position}</button>)
                            }
                            <NavLink to="/profile" exact><button className={classNames('btn', 'btn_sensor')} >추가하기</button></NavLink>
                        </div>
                        {!selectedSensor.isShare ? 
                            <div className="small_chart">
                                <div className="share_txt"> 
                                    영화의전당 <span className="txt_bold">&gt; {selectedSensor.position} </span>유동인구 통계가 궁금하신가요? <br></br><br></br>
                                    오른쪽 상단 <span style={{textDecoration: "underline"}}>'공유 요청하기'</span>를 통해 <span className="txt_bold">해운대구청</span>에게 접근 권한을 요청해보세요! <br></br><br></br>
                                    실시간/일별/주별/월별로 유동인구 데이터를 분석하거나 <br></br>
                                    상관관계 등의 고급 분석을 진행할 수 있습니다.&#32;&#32;:D
                                </div>
                                <div className="blur">
                                    <LineChartToday isSmall={true} isShare={selectedSensor.isShare}/>
                                </div>
                            </div>
                        :
                        <LineChartToday isSmall={true} isShare={selectedSensor.isShare}/>
                        }
                    </div>
                </>
                :   <div className="noData_wrapper">
                        <div><FiPlus size={45} color="#cccccc"/></div>
                        <div>장소목록에서 장소를 끌어올 수 있어요!</div>
                    </div>
                }
            </div>
            <RequestSensorModal open={ modal } close={ () => {setModal(false)} }>
            </RequestSensorModal>
        </>
    )
}

export default ChartItem;