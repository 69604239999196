import { useState } from "react";
import classNames from 'classnames';

import ScatterChartResult from '../charts/ScatterChartResult';

import temp_img1 from '../../../../resources/temp_res/temp_img1.png';


function DataReport(props){

    const [reportShow, setReportShow] = useState(true);

    return(
        <div className="populationReport">
        <div className="report_title">
            <div className="txt_bold_big">{props.data === 1 ? '유동인구 - 유동인구 상관분석' : '유동인구 - 자체 보유 데이터 상관분석'}</div>
            <div className="txt_gray">{props.data === 1 ? '두 장소의 유동인구 추이를 비교해보세요. 얼마나 유사한지 상관계수 값을 얻을 수 있습니다.' : '유동인구와 함께 분석하고 싶은 자체 보유 데이터 파일을 업로드해보세요! 두 데이터의 유사도를 분석할 수 있습니다.'}</div>
            <span className="btn_slide" onClick={() => setReportShow(!reportShow)}> {reportShow ? '▲' : '▼'} </span>
        </div>
        <div className={classNames("report_chart", reportShow ? 'show' : 'close')}>
            <div className="option_wrapper">
                <div className={classNames("picker", "positionPicker_wrapper")}>
                    <div className="positionPicker">
                    </div>
                    {props.data === 2 ?
                        <div className="filePicker">
                        </div> : ''
                    }
                </div>
                <div className={classNames("picker", props.data === 1 ? "dataPicker1" : "dataPicker2")}>
                </div>
                <div className="picker_btn">
                    <button id="reset">초기화</button>
                    <button id="analyze">분석하기</button>
                </div>
            </div>
            <div className="tipBox">
                <div>Tip ① 두 장소의 유동인구 유사도는? → "STEP1"에서 장소를 두 개 선택하신 수, "STEP2. 날짜"를 하나만 선택해보세요.</div>
                <div>Tip ② 한 장소에서 서로 다른 날의 유동인구 유사도는? → "STEP1. 장소/공간"을 하나만 선택하신 후, "STEP2"에서 날짜 두개를 선택해보세요.</div>
            </div>
            <div className="chart_wrapper">
                <div className="charts" id="chart1" data-content="분석 결과 ?">
                    <ScatterChartResult />
                </div>
                <div className="charts" id="chart2" data-content="로우데이터">
                    
                </div>
            </div>
            <div className="txt_bold">* 기술통계량</div>
            <img src={temp_img1} alt="graph"></img>
            <div className="report_result">
                <div>* 두 장소의 <span className="txt_bold">2021. 06. 08</span> 유동인구 <span>상관계수값은 0.82</span>로 강하게 유사한 것으로 분석되었습니다.</div>
                <div>* 분석일 기준 <span className="txt_bold">해운대해수욕장</span>에 시간대별로 나타난 유동인구의 전체 합은 <span className="txt_bold">1,245명</span>으로, <span className="txt_bold">15시</span>에 붐빔도가 가장 높았어요!</div>
                <div>* 분석일 기준 <span className="txt_bold">광안리해수욕장</span>에 시간대별로 나타난 유동인구의 전체 합은 <span className="txt_bold">1,045명</span>으로, <span className="txt_bold">17시</span>에 붐빔도가 가장 높았어요!</div>
            </div>
        </div>
    </div>
    )
}

export default DataReport;