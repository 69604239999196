import { useState, useEffect } from "react";
import { RequiredMark } from "../RequiredMark";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import PersonalInformationCollectionModal from "./PersonalInformationCollectionModal";
import Grid from "@mui/material/Grid";

export const PrivacyAndPolicyValidator = (props) => {
  const [agreeToUse, setAgreeToUse] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [dataCollection, setDataCollection] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const profile = props.profile;
  const setProfile = props.setProfile;

  useEffect(() => {
    profile.agree_to_use = agreeToUse;
    profile.privacy_and_policy = privacy;
    profile.user_data_collection = dataCollection;
    setProfile(profile);
  }, [agreeToUse, privacy, dataCollection]);

  const agreeToUseChangeHandler = () => {
    setAgreeToUse(!agreeToUse);
  };

  const privacyChangeHandler = () => {
    if (!privacy) {
      setShowPrivacyModal(true);
    } else {
      setPrivacy(false);
    }
  };

  const dataCollectionChangeHandler = () => {
    if (!dataCollection) {
      setShowCollectionModal(true);
    } else {
      setDataCollection(false);
    }
  };

  const showPrivacy = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };

  const showCollection = () => {
    setShowCollectionModal(!showCollectionModal);
  };

  return (
    <>
      <PrivacyPolicyModal
        open={showPrivacyModal}
        setOpen={setShowPrivacyModal}
        setPrivacy={setPrivacy}
      />
      <PersonalInformationCollectionModal
        open={showCollectionModal}
        setOpen={setShowCollectionModal}
        setDataCollection={setDataCollection}
      />

      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            value="end"
            className="privacy-checkbox"
            control={
              <Checkbox
                color="primary"
                onChange={agreeToUseChangeHandler}
                checked={agreeToUse}
                size="small"
              />
            }
            label="서비스 이용, 개인정보 수집 및 이용에 모두 동의 합니다!"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={9} md={11}>
          <FormControlLabel
            value="end"
            className="privacy-checkbox"
            control={
              <>
                <Checkbox
                  color="primary"
                  onChange={privacyChangeHandler}
                  checked={privacy}
                  size="small"
                />
                <RequiredMark />
              </>
            }
            label="서비스 이용약관 동의"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <Button fullWidth onClick={showPrivacy} color="primary" size="small">
            약관 보기{" "}
          </Button>
        </Grid>
        <Grid item xs={9} md={11}>
          <FormControlLabel
            value="end"
            className="privacy-checkbox"
            control={
              <>
                <Checkbox
                  color="primary"
                  onChange={dataCollectionChangeHandler}
                  checked={dataCollection}
                  size="small"
                />
                <RequiredMark />
              </>
            }
            label="개인정보 수집 및 이용 동의"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <Button
            fullWidth
            onClick={showCollection}
            color="primary"
            size="small"
          >
            약관 보기{" "}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
