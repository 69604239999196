import { FiCheckCircle } from "react-icons/fi";
import Profile from "../../../../../screens/platform/Profile";
import {Button} from "@material-ui/core";
import React from "react";
import {api_pcp} from "../../../../../api/api_pcp";
import {inject} from "mobx-react";

class TerminateComplete extends React.Component{

    terminateOk = () => {
        try{
            api_pcp.delete('/v1/auth/session').then((r) => {
                api_pcp.deleteHeader('Authorization');
                try {
                    typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
                } catch (e) {}
                try {
                    sessionStorage.removeItem('Authorization');
                    localStorage.removeItem('Authorization');
                } catch (e) { console.warn(e) }
                this.props.history.push('/sign-in');
            })
        } catch (e) {
            console.error(e)
            this.props.push('/');
        }
    }

    render() {
        return(
            <Profile>
                <div className="c_wrapper">
                    <div className="header">회원탈퇴</div>
                    <div className="complete_wrapper">
                        <FiCheckCircle size={300}/>
                        <div style={{marginTop: 30}}>회원 탈퇴가 정상적으로 마무리 되었습니다.</div>
                        <div>그동안 저희 플랫폼 서비스를이용하여 주셔서 감사합니다!</div>
                        <div>앞으로 더 나은 PCP로 다시 찾아뵐 수 있돌고 노력하겠습니다!</div>
                        <Button variant="outlined" style={{width: 200, marginTop: 50}} onClick={this.terminateOk}>첫 화면으로 가기</Button>
                    </div>
                </div>
            </Profile>
        )
    }
}

export default inject(['sessionStore'])(TerminateComplete);