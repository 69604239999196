import React, { useState } from 'react';

import '../../../../../css/platform/functions/ChartView.css';
import ChartItem from './ChartItem';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";


function ChartView(){
    const [show, setShow] = useState(false);

    const period_state = {
        "Daily": 1, "Weekly": 2, "Custom": 3
    }
    const [selectedPeriod, setSelectedPeriod] = useState(period_state.Daily);

    function openInformation(){       
        setShow(!show);
    }

    function btnPeriodHandler(key){
        setSelectedPeriod(key)
    }

    return(
        <>  
            <div id="chartView" className={show ? 'chartView_close' : 'chartView_open'} >
                <div id="openBtn" onClick={openInformation} className={show? 'openBtn' : 'openBtn_close'}> <span className={show? 'left_motion': 'right_motion'}>{show ? <RiArrowRightSLine size={20} color="skyblue"/> : <RiArrowLeftSLine size={20} color="pink"/>}</span>더 자세히, 더 많이</div>
                <div id="charView_wrapper">
                    <div id="select_topic">
                        <span className={selectedPeriod === period_state.Daily ? 'selected' : ''} onClick={() => btnPeriodHandler(period_state.Daily)}>영화의전당</span>
                        <span className={selectedPeriod === period_state.Weekly ? 'selected' : ''} onClick={() => btnPeriodHandler(period_state.Weekly)}>장소2</span>
                        <span className={selectedPeriod === period_state.Custom ? 'selected' : ''} onClick={() => btnPeriodHandler(period_state.Custom)}>장소3</span>
                    </div>

                    <div id="chartItem_wrapper">
                        {/* Todo key setting */}
                        <ChartItem key={'chart-1'}  isData={true} />
                        <ChartItem key={'chart-2'}  isData={false} />
                        <ChartItem key={'chart-3'}  isData={false} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChartView;