import React from 'react';
import {
    Button, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, TextField
} from "@material-ui/core";
import CompleteModal from "./CompleteModal";
import {api_pcp} from "../api/api_pcp";
import '../css/platform/functions/functionthree/modal.css';

export default class AgencySelectModal extends React.Component {

    state = ({
        query: '',
        searched: false,
        secondModal: false,
        group: "organization",
        groupArr: []
    })

    searchOrganization = (query = this.state.query) => {
        this.setState({searched: true});
        api_pcp.get(`/v1/${this.state.group}`, {
            q: query
        }).then((res) => {
            if (!res.ok) return alert('데이터를 불러오지 못했습니다.');
            let groupArr = [];
            if(this.state.group === "organization"){
                groupArr = (res.data?.organizations || []).map((e,index) => ({"index" : index, ...e}));
            }else{
                groupArr = (res.data?.companies || []).map((e,index) => ({"index" : index, ...e}));
            }
            this.setState({groupArr}, this.setState({query: ''}));
        }).catch((err) => {
            console.err(err);
        })
    }

    onClickSearch = () => {
        const query = this.state.query;
        if (!query) return alert('내용을 입력해주세요');
        this.searchOrganization(query);
    }

    onChangeQuery = (e) => {
        this.setState({query : e.target.value})
    }

    onKeyPress = (e) => {
        if(e.key === 'Enter') this.onClickSearch()
    }

    closeModal = () => {
        this.initForm();
        this.props.close(false);
        this.props.setDataChange();
    }

    selectGroup(i) {
        let group = this.state.group + "_uuid";
        api_pcp.put('/v1/user/me', {
            [group]: this.state.groupArr[i].uuid,
        }).then((res) => {
            if (!res.ok) return alert('소속 기관을 변경할 수 없습니다');
            this.setState({
                secondModal: true
            })
        }).catch((err) => {
            console.err(err);
        })
    }

    selectRadio = (e) =>{
        this.initForm();
        this.setState({group: e.target.value})
    }

    initForm = () => {
        this.setState({
            query: '',
            searched: false,
            group: "organization",
            groupArr: []
        })
    }

    render() {
        const { open } = this.props;
        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                    <DialogContent className="s-modal">
                        <button className="close-btn" onClick={this.closeModal}> &times; </button>
                        <div>
                            <div className="search_wrapper">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" defaultValue="organization" onChange={this.selectRadio}>
                                        <FormControlLabel value="organization" control={<Radio color="primary" />} label="공공" />
                                        <FormControlLabel value="company" control={<Radio color="primary" />} label="기업" />
                                    </RadioGroup>
                                </FormControl>
                                <div className="modal-sub-title" >{this.state.group === "organization" ? "찾고자 하는 단체 / 기관의 이름을 검색해주세요" : "사업자등록번호 / 법인등록번호 / 기업명을 입력해주세요"}</div>
                                <div>
                                    <TextField autoComplete="off" variant="outlined" size="small" style={{ width: 420 }} value={this.state.query} onChange={this.onChangeQuery} onKeyPress={this.onKeyPress}/>
                                    <Button variant="outlined" style={{ width: 100, height: 40, marginLeft: 20 }} onClick={this.onClickSearch}>검색</Button>
                                    <div style={{fontSize: 14}}>{this.state.group === "organization" ? "* 두 단어 이상 입력해주세요." : "* 등록번호는 (-)를 제외하고 입력해주세요."}</div>
                                </div>
                                <div className="modal-sub-title" style={{marginTop: 50, marginBottom: 20}}>{this.state.group === "organization" ? "해당하는 단체/기관을 선택해 주세요" : "이 정보가 맞나요?"}</div>
                                <div className="organization_list">
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            {
                                                this.state.group === "organization" ?
                                                    <TableRow>
                                                        <TableCell align="center" style={{ width: 150, borderRight: "1px solid rgba(0, 0, 0, 0.23)"}}>지역</TableCell>
                                                        <TableCell align="center" style={{ width: "calc(550px - 100px)" }}>단체/기관 명</TableCell>
                                                    </TableRow> : null
                                            }
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.searched && this.state.groupArr.length === 0 ?
                                                    <TableRow>
                                                        <TableCell colSpan="2" align="center" className="no_data">검색하신 내용이 없습니다. <br/>기업이름을 다시 한번 확인해주세요.</TableCell>
                                                    </TableRow> :
                                                    this.state.group === "organization" ?
                                                    this.state.groupArr.map((e, index) => {
                                                        return (
                                                            <TableRow hover tabIndex={-1} key={index} onClick={() => this.selectGroup(index)} style={{cursor: "pointer"}}>
                                                                <TableCell align="center" style={{borderRight: "1px solid rgba(0, 0, 0, 0.23)"}}>{e.address.substr(0, e.address.indexOf(' '))}</TableCell>
                                                                <TableCell align="center">{e.name}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }):
                                                    this.state.group === "company" ?
                                                        this.state.groupArr.map((e, index) => {
                                                            return (
                                                                <TableRow hover tabIndex={-1} key={index} onClick={() => this.selectGroup(index)} style={{cursor: "pointer"}}>
                                                                    <TableCell>
                                                                        <div style={{width: "100%"}}>
                                                                            <div className="txt_bold">{e.name}</div>
                                                                            <div>업종 : {e.business_type}&nbsp;&nbsp;|&nbsp;&nbsp;기업규모 : {e.business_scale}</div>
                                                                            <div>{e.address}</div>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }): null
                                            }
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <CompleteModal open={ this.state.secondModal } close={ () => this.setState({ secondModal: false })} size={"small"}  parentClose={this.closeModal}>
                            소속기관 변경이 완료되었습니다!
                        </CompleteModal>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}