import React from 'react';
import { NavLink } from 'react-router-dom';

import '../../../../../css/platform/functions/RequestSensorModal.css';


export default class CompleteSensorModal extends React.Component {

    render() {
        const { open, close } = this.props;

        return (
            <div className={ open ? 'openModal fmodal': 'fmodal' }>
                { open ? (  
                    <section>
                        <button className="close" onClick={close}> &times; </button>
                        <div className="complete_txt_wrapper">
                            <div>영화의전당 <span className="txt_bold">&gt; 더블콘</span>에 대한 데이터 공유 요청이 <span className="txt_bold">정상 접수</span> 되었습니다!</div><br></br>
                            <div><span className="txt_bold">수영구청</span>의 요청 수락까지는 약 7일의 시간이 소요될 수 있으며, <br></br>
                            마이페이지 - <span className="txt_bold">'센서 현황'</span>에서 처리 상태를 확인할 수 있습니다 :D</div>

                            <div className="btn_wrapper">
                                <NavLink to="/profile/enroll-new-place" exact><button className="btn">센서 현황 바로가기</button></NavLink>
                                <button className="btn" onClick={close}>네 확인했어요!</button>
                            </div>
                        </div>
                    </section>
                ) : null }
            </div>
        )
    }
}