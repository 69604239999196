export class Organization {
    "uuid": string;
    "created_at": number;
    "updated_at": number;
    "deleted_at": number;
    "id": number;
    "name": string;
    "address": string;

    constructor(organization: Organization) {
        Object.assign(this, organization);
    }
}