import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { useState } from "react/cjs/react.development";
import { api_pcp } from "../api/api_pcp";
import { useFormInput } from "../utils/InputUtils";
import "../css/modals/modal.css";

const OrgAndCompModal = (props) => {
  const [agencyType, setAgencyType] = useState("organization");
  const [agencyData, setAgencyData] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState({});
  const handleClose = props.handleClose;
  let isLockedSubmit = false;
  let profile = props.profile;

  const searchHandler = (type) => {
    if (!type) {
      type = agencyType;
    }
    if (isLockedSubmit) {
      return;
    }

    const term = agency.value;
    if (!term) {
      setAgencyData([]);
      return;
    }

    isLockedSubmit = true;
    api_pcp.get("/v1/" + type, { q: term }).then((r) => {
      let data = null;

      if (!r.ok) {
        return;
      }

      if (type === "organization") {
        data = r.data.organizations;
      } else {
        data = r.data.companies;
      }
      setAgencyData(data);
    });
    isLockedSubmit = false;
  };

  const agency = useFormInput("", { onPressEnter: searchHandler });

  const onChange = (e) => {
    setAgencyType(e.target.value);
    searchHandler(e.target.value);
  };

  const selectAgencyHandler = (e) => {
    setSelectedAgency(e.target.value);
  };

  const submitHandler = () => {
    agencyData.forEach((obj) => {
      if (obj.uuid === selectedAgency) {
        profile.agency_name = obj.name;
        if (agencyType === "organization") {
          profile.organization_uuid = obj.uuid;
          profile.company_uuid = "";
        } else {
          profile.company_uuid = obj.uuid;
          profile.organization_uuid = "";
        }
        props.setProfile(profile);
      }
    });

    handleClose();
  };

  const agencyList = agencyData.map((obj) => {
    return (
      <div className="agency-list-item" key={agencyType + obj.uuid}>
        <input
          type="radio"
          name="agency"
          id={"agency-" + obj.id}
          value={obj.uuid}
        />
        <label htmlFor={"agency-" + obj.id}>
          <p>{obj.name}</p>
          {agencyType === "company" ? (
            <>
              <span>
                <div>업종:</div>
                {obj.business_type}
              </span>
              <span>
                <div>기업규모:</div>
                {obj.business_scale}
              </span>
              <span>
                <div>주소:</div>
                {obj.address}
              </span>
            </>
          ) : (
            <span>주소: {obj.address}</span>
          )}
        </label>
      </div>
    );
  });

  return (
    <Dialog
      open={props.open}
      keepMounted={true}
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="Organization and Company Modal"
      className="comp-org-modal"
    >
      <DialogContent>
        <Grid container spacing={2} className="comp-org-modal-grid">
          <Grid item md={12}>
            <h1 className="agency-title">회원 정보 입력 </h1>
          </Grid>
          <Grid item md={12}>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item xs={12} md={3}>
                <span className="agency-type-title">소속기관</span>
              </Grid>
              <Grid item xs={12} md={9}>
                <RadioGroup
                  row
                  aria-label="position"
                  onChange={onChange}
                  name="member-type"
                  value={agencyType}
                >
                  <FormControlLabel
                    value="organization"
                    control={<Radio color="primary" />}
                    label="공공(정부/지방자치단체/관공서)"
                    className="org-label"
                  />
                  <FormControlLabel
                    value="company"
                    control={<Radio color="primary" />}
                    label="기업"
                    className="org-label"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth={true}
                  label="Search here"
                  variant="outlined"
                  className="agency-input"
                  {...agency}
                />
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" fullWidth onClick={searchHandler}>
                  검색
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="agencies-list" onChange={selectAgencyHandler}>
              {agencyList.length === 0 ? (
                <span className="agency-list-empty-msg">
                  검색하신 내용이 없습니다! <br />
                  기업 이름을 다시 한번 확인해주세요.
                </span>
              ) : null}
              {agencyList}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  className="agency-cancel-btn"
                  onClick={handleClose}
                >
                  닫기
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  className="agency-submit-btn"
                  onClick={submitHandler}
                >
                  확인 !
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <div className="agency-modal"> */}

        <div className="agency-type"></div>

        <div className="modal-control-btns"></div>
        {/* </div> */}
      </DialogContent>
    </Dialog>
  );
};

export default OrgAndCompModal;
