import React from "react";
import { TileLayer } from "react-leaflet";

import "../../../../css/platform/functions/MapTool.css";
export default class MapTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: "midnight",
    };
  }

  render() {
    return (
      <>
        <div className="map_tool_wrapper">
          <div className="map_btn_wrapper">
            <div className="map_option_wrapper">
              <span>
                유동
                <br />
                인구
              </span>
              <div className="map_option">
                <span>우리 장소</span>
                <span>공유받은 장소</span>
                <span>잠겨있는 장소</span>
              </div>
            </div>
            <div className="map_option_wrapper">
              <span>
                실내
                <br />
                실외
              </span>
              <div className="map_option">
                <span>실내</span>
                <span>실외</span>
              </div>
            </div>
            <div className="map_option_wrapper">
              <span>
                지도
                <br />
                유형
              </span>
              <div className="map_option">
                <span onClick={() => this.setState({ map: "Base" })}>일반지도</span>
                <span onClick={() => this.setState({ map: "midnight" })}>믿나잍뷰</span>
                <span onClick={() => this.setState({ map: "Satellite" })}>스카이뷰</span>
              </div>
            </div>
          </div>
        </div>
        <GetTile key={"map-" + this.state.map} map={this.state.map} />
      </>
    );
  }
}

function GetTile(props) {
  const mapType = props.map;
  let imageType = "png";
  if (props.map === "Satellite") {
    imageType = "jpeg";
  }
  const url = `https://api.vworld.kr/req/wmts/1.0.0/6A16D893-B0C9-3AC1-A2AB-25C70B2D480D/${mapType}/{z}/{y}/{x}.${imageType}`;
  return (
    <TileLayer
      attribution='&copy; <a href="https://map.vworld.kr" target="_blank">vworld</a> contributors'
      url={url}
      minZoom="7"
      maxZoom="19"
    />
  );
}
