import { useState } from "react"
import { useMap } from "react-leaflet";

import '../../../../css/platform/functions/function-one.css'

const ZoomListener = () => {
    const map = useMap()
    const [zoom, setZoom] = useState(map.getZoom());
    

    map.on('zoomend', () => {
        const mapZoom = map.getZoom();
        setZoom(mapZoom);
    })

    return(
        <span className="zoom-listener">Zoom: {zoom}</span>
    )
}

export default ZoomListener;