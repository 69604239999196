import React from 'react';
import Select from 'react-select'

import '../../../../../css/platform/functions/RequestSensorModal.css';
import CompleteSensorModal from './CompleteSensorModal';


export default class RequestSensorModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showTextArea: false,
            textArea_length: 0,
            secondModal: false            
        };
    }

    checkByte = (e) => {
        let str = e.target.value;
        let str_length = str.length;

        if(str_length > 1000){
            alert('1000글자를 초과했습니다');
            e.target.value = str.substr(0,1000);
        }else{
            this.setState({
                textArea_length: str_length
            });    
        }
    }

    selectOption = (e) => {
        let bol = e.value === 3 ? true : false;
        this.setState({
            showTextArea: bol
        });
    }

    modalClose = () => {
        this.setState({
            secondModal: true
        })
        this.props.close(false);
    }

    render() {
        const { open, close } = this.props;
        const options =[
            {label: '단순 유동인구 데이터 확인', value: 0},
            {label: '보고서(결과/주간/월간/분기 등) 참고자료 활용', value: 1},
            {label: '연구(논문) 참고자료 활용', value: 2},
            {label: '직접 입력 (최대 1,000자)', value: 3},
        ]       

        return (
            <>
                <div className={ open ? 'openModal fmodal': 'fmodal' }>
                    { open ? (  
                        <section>
                            <button className="close" onClick={close}> &times; </button>
                            <div className="header">데이터 공유 요청</div>
                            <div className="input_wrapper">
                                <div><span>장소</span>영화의전당 <span className="txt_bold">&gt; 더블콘</span></div>
                                <div><span>소유자</span><span className="txt_bold">수영구청</span></div>
                                <div><span>요청자</span><span className="txt_bold">easyun46</span> (소속기관: 해운대구청)</div>
                                <div><span>요청사유</span>
                                    <Select options={options} id="options" onChange={this.selectOption} />
                                </div>

                                <div className={this.state.showTextArea ? "" : "direct_input_wrapper"}>
                                    <div className="small_txt">{this.state.textArea_length}/1000 자</div>
                                    <textarea className="direct_input" rows="9" onChange={this.checkByte} placeholder="데이터 요청사유를 1,000자 이내로 작성하여 주세요!"></textarea>
                                    <div className="small_txt">
                                        * 내용 중 비방·욕설 등을 입력하는 경우 통보없이 해당 내용이 수정 및 삭제 처리될 수 있습니다! 또한, 요청 완료 후에는 내용 수정이 불가하므로 신중히 작성하여 주시기 바랍니다!
                                    </div>
                                </div>

                                <div>
                                    휴대폰 번호도 함께 제공하시겠습니까?
                                    <label>
                                        <input type="radio" value="yes" name="confirm_phone" className="radioBtn"/> 예
                                    </label>
                                    <label>
                                        <input type="radio" value="no" name="confirm_phone" className="radioBtn" defaultChecked/> 아니요
                                    </label>
                                    <div className="small_txt">* 휴대폰 번호를 제공할 경우, 소유자와의 직접적인 연락이 가능해집니다!</div>
                                </div>
                                <button className="btn" onClick={this.modalClose}>완료</button>
                            </div>
                        </section>
                    ) : null }
                </div>
                <CompleteSensorModal open={ this.state.secondModal } close={ () => this.setState({secondModal: false}) }>
                </CompleteSensorModal>
            </>
        )
    }
}