import { useEffect, useState } from 'react';
import { BsChevronLeft, BsList } from "react-icons/bs";
import { Accordion } from 'react-accessible-accordion';
import { useFormInput } from "../../../../utils/InputUtils";
import { api_pcp } from "../../../../api/api_pcp";
import { Button } from '@material-ui/core';
import Select from "react-select";
import PopulationInformation from '../../../../components/platform/functions/functionone/populationinfo/PopulationInformation';
import withMountEvent from '../../../withMountEvent';
import selectAnimData from '../../../../resources/lottie/select.json';
import emptyListAnimData from '../../../../resources/lottie/empty-list.json';
import loadingAnimData from '../../../../resources/lottie/loading.json';

import '../../../../css/platform/functions/places-list.css';
import Lottie from 'react-lottie';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";

// import PlaceItem from './PLaceItem';
// import placesList from '../../../../resources/platform/functions/plases-list.json';

const Selectify = (data, regions) => {
    let new_data = data.map((obj) => {
        return({
            value: obj.id,
            label: obj.name_kr
        });
    });

    if (!regions) new_data.unshift({
        value: 'all',
        label: '전체'
    });

    return new_data
}

const PlacesList = (props) => {
    // const [places, setPlaces] = useState([]);
    const [ show, setShow ] = useState(false);
    const [ region, setRegion ] = useState(null);
    const [ subRegion, setSubRegion ] = useState(null);
    const [ selectList, setSelectList ] = useState([]);
//    const [ placesList, setPlacesList ] = useState(null);
    const [ placesList, setPlacesList ] = useState(['영화의전당', '광안리해수욕장', '반여농산물시장', '재송한마음시장', '해운대해수욕장', '민락 수변공원']);
    const [ isLockedSubmit, setIsLockedSubmit ] =  useState(false);
    const searchTerm = useFormInput("");
    const setLineData = props.setLineData;
    const history = useHistory();
    

    const selectAnim = {
        loop: true,
        autoplay: true, 
        animationData: selectAnimData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    const emptyAnim = {
        loop: true,
        autoplay: true, 
        animationData: emptyListAnimData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const loadingAnim = {
        loop: true,
        autoplay: true, 
        animationData: loadingAnimData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const toggleShow = () => {
        setShow(!show);
    }

    const selectUpdateHandler = (values) => {
        const len = values.length;

        if (len === 1 ) {
            setRegion(values[0])
            setSubRegion(null)
        } else if(len === 2){
            setSelectList([]);
            setSubRegion(values[1]);
        } else if(len === 0) {
            setRegion(null);
            setSubRegion(null);
            setPlacesList(null)
        }
    }

    const redirectToNewPlaceEnroll = () => {
        history.push('profile/enroll-new-place')
    }
    
    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 500);

    }, [])

    useEffect(() => {
        let url = '/v1/region/';

        if (subRegion) {
            setSelectList([]);
            setLineData(null);
            setIsLockedSubmit(true);
            if( subRegion.value === "all" ){
                url += region.value + '/geojson';
            }else{
                url += region.value + '/sub/' + subRegion.value + '/geojson'
            }

            document.querySelectorAll('#city-line-select input')[0].blur();
            api_pcp.get(url).then(res => {
                if(!res.ok){
                    setIsLockedSubmit(false);
                    return ;
                }
                setLineData(res.data);
                setTimeout(() => {
                    setIsLockedSubmit(false);
                    setPlacesList([]);
                    
                }, 2000)
            });
            return;

        } else if(region){
            url += region.value + '/sub';
        }

        api_pcp.get(url).then(res => {
            if(!res.ok) {
                return ;
            }

            let data = [];
            if (region) {
                data = res.data?.region_subs;
                setSelectList(Selectify(data, false));
            }else {
                data = res.data?.regions
                setSelectList(Selectify(data, true))
            }
        });

    }, [setLineData, region, subRegion])

    return(
        <>
            <div className={show ? 'places-list-wrapper show': 'places-list-wrapper hide'} >
                <div className={show? "control-btn btn-show": "control-btn btn-hide"} onClick={toggleShow}>
                    {show ? <BsChevronLeft />:<BsList />}
                </div>
                <h5 className="header">장소 목록</h5>

                <div className="search">
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        id="city-line-select"
                        isClearable={true}
                        options={selectList}
                        onChange={selectUpdateHandler}
                        placeholder=" 지역을 검색해보세요!"
                        className="city-line-select"
                        classNamePrefix="custom-select"
                    />
                </div>

                <div className="search">
                    <input className="search-input" placeholder={'장소/공간명을 입력해보세요!'} {...searchTerm}/>
                </div>
                <hr />
                {isLockedSubmit &&
                    <div className='select-region-animation animate__animated animate__fadeIn'>
                        <Lottie options={loadingAnim}
                                height={30}
                                width={60}

                        >
                        </Lottie>
                    </div>
                }

                <ul>
                    {
                        placesList && placesList.map(item => {
                            return <li style={{border: '1px solid black', width: '100%', padding: 10, marginBottom: 5}}>{item} <IoIosArrowDown style={{cursor: 'pointer', float: 'right', marginTop: 5}}/></li>
                        })

                    }
                </ul>
                {/*{placesList ? */}
                {/*    placesList.length === 0 ? */}
                {/*        <div className='select-region-animation animate__animated animate__fadeIn'>*/}
                {/*        <Lottie options={emptyAnim}*/}
                {/*                height={200}*/}
                {/*                width={200}*/}
                {/*        >*/}
                {/*        </Lottie>*/}


                {/*        <span className='message'>장소 목록이 비어 있습니다..</span>*/}
                {/*        <Button onClick={redirectToNewPlaceEnroll} variant='contained'>장소 추가</Button>*/}
                {/*    </div>:*/}
                {/*    <Accordion className='className="sortable-places-list"' allowZeroExpanded >*/}
                {/*        /!* {placesList} *!/*/}
                {/*    </Accordion> */}
                {/*    */}
                {/*    :*/}
                {/*    !isLockedSubmit &&*/}
                {/*    <div className='select-region-animation animate__animated animate__fadeIn'>*/}
                {/*        <Lottie options={selectAnim}*/}
                {/*                height={100}*/}
                {/*                width={100}*/}

                {/*        >*/}
                {/*        </Lottie>*/}
                {/*        <span className='message'>지역을 선택해주세요.</span>*/}
                {/*    </div>*/}
                {/*}*/}
                
                <Button className="detailed-analysis" variant='contained'>상세 분석</Button>

            </div>
            {/*<PopulationInformation isShow={show}/>*/}
        </>
    )
}



export default withMountEvent(PlacesList, 'PlacesList');
