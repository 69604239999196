import { useEffect, useState } from "react";
import { RequiredMark } from "../RequiredMark";
import { OrganizationSelectModal } from "../../../modals/OrganizationSelectModal";
import { CompanySelectModal } from "../../../modals/CompanySelectModal";
import { useFormInput } from "../../../utils/InputUtils";
import { TextField, Button } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@mui/material/Grid";

export const OrgAndCompany = (props) => {
  const [memberType, setMemberType] = useState("organization");
  const [org, setOrg] = useState("");
  const [orgUUID, setOrgUUID] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const extraInfo = useFormInput("");
  const profile = props.profile;
  const setProfile = props.setProfile;

  const memberTypeChangeHandler = (e) => {
    setMemberType(e.target.value);
  };

  const SearchClickHandler = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (memberType === "organization") {
      profile.organization_uuid = orgUUID;
      profile.company_uuid = null;
    } else if (memberType === "company") {
      profile.company_uuid = orgUUID;
      profile.organization_uuid = null;
    }
    setProfile(profile);
  });

  return (
    <>
      <Grid container spacing={3}>
        <OrganizationSelectModal
          setName={setOrg}
          setUUID={setOrgUUID}
          setOpen={setOpenModal}
          open={memberType === "organization" && openModal}
        />
        <CompanySelectModal
          setName={setOrg}
          setUUID={setOrgUUID}
          setOpen={setOpenModal}
          open={memberType === "company" && openModal}
        />
        <Grid item xs={12}>
          <h1>
            회원 정보 입력 (<RequiredMark /> 표시는 필수입력사항입니다!)
          </h1>
        </Grid>
        <Grid item xs={12}>
          <p>
            입력하신 회원 정보는 회원님의 동의 없이 공개되지 않으며,
            개인정보취급방침에 따라 안전하게 보호됩니다.
          </p>
        </Grid>
        <Grid item container alignItems={"center"} xs={12}>
          <Grid xs={12} md={2}>
            <h1>
              <RequiredMark /> 소속기관
            </h1>
          </Grid>
          <Grid xs={12} md={10} onChange={memberTypeChangeHandler}>
            <RadioGroup
              row
              aria-label="position"
              onChange={memberTypeChangeHandler}
              name="member-type"
              defaultValue={memberType}
            >
              <FormControlLabel
                value="organization"
                control={<Radio color="primary" size="small" />}
                label="공공(정부/지방자치단체/관공서)"
              />
              <FormControlLabel
                value="company"
                control={<Radio color="primary" size="small" />}
                label="기업"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <TextField
                  className={"signup-text-input"}
                  value={org}
                  size="small"
                  onClick={SearchClickHandler}
                  type={"text"}
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  variant={"outlined"}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  className={"signup-btn"}
                  variant={"contained"}
                  onClick={SearchClickHandler}
                >
                  검색
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid paddingBottom={"1rem"} xs={12} md={2}>
            <h1>부서</h1>
          </Grid>
          <Grid xs={12} md={5}>
            <TextField
              fullWidth
              size="small"
              type="text"
              label="ex. 스마트시티추진과"
              className={"signup-text-input"}
              variant={"outlined"}
              helperText="*같은 소속기관에서 여러 개의 계정을 사용하는 경우, 부서이름으로 구분할 수 있어요!"
              {...extraInfo}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
