import React from "react";
import { Button, Dialog, DialogContent, TextField } from "@material-ui/core";
import {api_pcp} from "../api/api_pcp";
import CompleteModal from "./CompleteModal";
import '../css/platform/functions/functionthree/modal.css';

export default class PhoneNumChangeModal extends React.Component{

    state = {
        secondModal: false,
        phone: '',
        isSend: false,
        count: 0,
        timer: 0,
        leftTime: {},
        reqKey: '',
        authCode: '',
        phoneInput: true
    }

    onChange = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        let tmp = '';
        if( e.target.value.length < 4){
            tmp += e.target.value
        }else if(e.target.value.length < 8){
            tmp += e.target.value.substr(0, 3);
            tmp += '-';
            tmp += e.target.value.substr(3);
        }else{
            tmp += e.target.value.substr(0, 3);
            tmp += '-';
            tmp += e.target.value.substr(3, 4);
            tmp += '-';
            tmp += e.target.value.substr(7, 4);
        }
        this.setState({phone: tmp});
    }

    countDown = () => {
        let count = this.state.count - 1;
        this.setState({
            leftTime: this.secondToTime(count),
            count: count
        })

        if(count === -1){
            clearInterval(this.timer)
            this.initForm();
            alert("인증 유효기간이 만료되었습니다. 다시 시도해주세요");
        }
    }

    secondToTime = (secs) => {
        let minutes = Math.floor(secs % (60 * 60) / 60);
        let seconds = Math.ceil(secs % (60 * 60) % 60);
        return {
            "m": minutes,
            "s": seconds
        };
    }

    sendAuthentication = () => {
        if(this.state.phone.length !== 13){
            return alert('올바르지 않은 휴대폰 번호 형식입니다')
        }
        api_pcp.post('/v1/user/me/phone-number', {
            phone_number: this.state.phone.replaceAll('-', ''),
        }).then((res) => {
            if (!res.ok) return alert('인증번호 요청에 실패했습니다');
            let count = res.data.expired_at - res.data.issued_at;
            this.setState({
                reqKey: res.data.request_key,
                count: count,
                leftTime: {m: count/60, s: count%60},
                phoneInput: false
            });

            if(!this.state.isSend){
                this.setState({
                    isSend: true,
                });
                if(this.state.timer === 0 && this.state.count > 0){
                    this.timer = setInterval(this.countDown, 1000)
                }
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    onAuthOk = () => {
        if(this.state.count !== 0){
            api_pcp.put('/v1/user/me/phone-number', {
                request_key: this.state.reqKey,
                auth_code: this.state.authCode
            }).then((res) => {
                if (!res.ok) return alert('인증번호를 다시 확인해주세요');
                this.onPhoneChange();
                clearInterval(this.timer);
            }).catch((err) => {
                console.error(err);
            })
        }
    }

    onPhoneChange = () => {
        this.setState({
            secondModal: true
        })
    }

    closeModal = () => {
        this.initForm();
        this.props.setDataChange();
        this.props.close(false);
    }

    initForm = () => {
        this.setState({
            phone: '',
            isSend: false,
            count: 0,
            timer: 0,
            leftTime: {},
            reqKey: '',
            authCode: '',
            phoneInput: true
        })
        clearInterval(this.timer);
    }

    render() {
        const { open } = this.props;

        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                    <DialogContent className="c-modal">
                        <button className="close-btn" onClick={this.closeModal}> &times; </button>
                        <div className="changePassword_wrapper">
                            <div className="modal-title">휴대폰 변경</div>
                            <div className="mb-30">
                                아이디, 비밀번호 찾기 등 본인확인이 필요한 경우 사용되는 전화번호입니다. <br/>
                                새로운 전화번호를 입력하여 주세요!
                            </div>
                            <div style={{width: 540, textAlign: "right"}}>
                                <TextField
                                    autoComplete="off"
                                    variant="outlined"
                                    label="휴대폰 번호"
                                    size="small"
                                    style={{ width: 400 }}
                                    value={this.state.phone}
                                    onChange={this.onChange}
                                    type="text"
                                    disabled={!this.state.phoneInput}
                                />
                                <Button variant="outlined" disabled={!this.state.phoneInput} onClick={this.sendAuthentication} style={{width: 120, marginLeft: 20, height: 40}}>인증번호 전송</Button>
                                <div style={{marginTop: 30, visibility: this.state.isSend ? "visible" : "hidden"}}>
                                    <TextField
                                        autoComplete="off"
                                        variant="outlined"
                                        label="인증번호 입력"
                                        size="small"
                                        style={{ width: 150 }}
                                        type="number"
                                        value={this.state.authCode}
                                        onChange={(e) => {this.setState({authCode: e.target.value})}}
                                    />
                                    <Button disabled style={{width: 40, marginLeft: 5}}>{this.state.leftTime.m} : {this.state.leftTime.s}</Button>
                                    <Button variant="outlined" onClick={this.onAuthOk} style={{width: 66, marginLeft: 5, height: 40}}>확인</Button>
                                </div>
                            </div>
                            {/*<Button variant="outlined" onClick={this.onPhoneChange} style={{width: 150, float: "right", marginTop: 100}}>휴대폰 변경</Button>*/}
                        </div>
                        <CompleteModal open={ this.state.secondModal } close={ () => this.setState({secondModal: false}) } parentClose={this.closeModal}>
                            휴대폰번호 변경이 완료되었습니다!
                        </CompleteModal>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}