import {User} from "../models/User";
import {action, observable, runInAction} from "mobx";
import BaseStore from "./BaseStore";
import {api_pcp} from "../api/api_pcp";
import jwt from 'jsonwebtoken';
import {StorageUtils} from "../utils/StorageUtils";

export default class SessionStore extends BaseStore {
  token: string;
  @observable.deep user: User;

  constructor(rootStore) {
    super(rootStore);

    // Initialize
    if (!this.user) {
      const authorization = StorageUtils.getAuthorization();
      if (authorization) {
        try {
          const payload = jwt.decode(authorization, {});
          this.setUser(new User({...payload}));
        } catch (e) { console.error(e) }
      }
    }
  }

  @action
  reset() {
    this.user = null;
    this.token = null;
  }

  @action
  setToken(token: string) {
    runInAction(() => {
      this.token = token;
    });
  }

  @action
  setUser(user: User) {
    this.user = user;
  }

  @action
  fetchMe(){
    return new Promise ((resolve, reject) => {
      api_pcp.get('/v1/user/me', {jp: 1, jo: 1, jc: 1}).then((r) => {
        if(!r.ok) {
          console.warn(r);
          reject(r);
          return;
        }

        const profile = r.data.user;
        console.log("User Profle", profile);

        if(!profile){
          return
        }

        this.setUser(new User(profile))
        resolve();
      }).catch((e) => {
        console.error(e);
        return reject(e);
      });
    })
  }

  @action
  signInWithToken(params, keepSession: bool): Promise {
    return new Promise((resolve, reject) => {

      api_pcp.post('/v1/sign-up/', params).then((r) => {
        if(!r.ok){
          console.log(r);
          return;
        }
        const token = r.data?.token;
        if(!token){
          console.error('Token does not exist');
          reject(r);
          return;
        }

        const payload = jwt.decode(token, {});
        console.log('Decoded JWT', payload);
        // Save JWT to LocalStorage
        StorageUtils.setAuthorization(token, keepSession);

        // 저장된 JWT 체크
        if (token === StorageUtils.getAuthorization()) {
          console.log('Sign-in success', token);

          runInAction(() => {
            this.setUser(new User({...payload}))
          });

          api_pcp.setHeader('Authorization', token);
          resolve();
        } else {
          console.warn('An error occurred while save token to local');
          reject(r);
        }

        console.log(r.data?.token);
      })
    })
  }

  @action
  signIn(username: string, password: string, keepSession: boolean): Promise {
    return new Promise((resolve, reject) => {
      api_pcp.post('/v1/auth/sign-in', {username, password}).then((r) => {
        if (!r.ok) {
          console.warn(r);
          reject(r);
          return;
        }

        const token = r.data.token;
        if (!token) {
          console.error('Token is not exists', r);
          reject('TOKEN_IS_NOT_EXISTS');
          return;
        }

        const payload = jwt.decode(token, {});
        console.log('Decoded JWT', payload);

        // Save JWT to LocalStorage
        StorageUtils.setAuthorization(token, keepSession);

        // 저장된 JWT 체크
        if (token === StorageUtils.getAuthorization()) {
          console.log('Sign-in success', token);

          runInAction(() => {
            this.setUser(new User({...payload}))
          });

          api_pcp.setHeader('Authorization', token);
          resolve();
        } else {
          console.warn('An error occurred while save token to local');
          reject(r);
        }
      }).catch((e) => {
        console.error(e);
        return reject(e);
      });
    });
  }
}