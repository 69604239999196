import { useState } from "react";
import { RequiredMark } from "../RequiredMark";
import { useFormInput } from "../../../utils/InputUtils";
import { api_pcp } from "../../../api/api_pcp";
import { IdValidationModal } from "../../../modals/IdValidationModal";
import { TextField, Button } from "@material-ui/core";
import Grid from "@mui/material/Grid";

export const ValidateId = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [msg, setMsg] = useState("empty");
  const username = useFormInput("", { only_eng: true });

  const setProfile = props.setProfile;
  let profile = props.profile;

  const idCheckHandler = () => {
    if (username.value === "") {
      setMsg("empty");
    }
    api_pcp
      .get("/v1/sign-up/username/available/", { q: username.value })
      .then((res) => {
        if (!res.ok) {
          console.log("Server Error");
        }
        if (!res.data.available) {
          setMsg("taken");
          setOpenModal(true);
          return;
        }
        setMsg("available");
        profile.username = username.value;
        setProfile(profile);
        setOpenModal(true);
      });
  };

  return (
    <Grid container spacing={2}>
      <IdValidationModal open={openModal} setOpen={setOpenModal} msg={msg} />
      <Grid item xs={12} md={2}>
        <h1>
          <RequiredMark /> 아이디
        </h1>
      </Grid>
      <Grid item container spacing={1} xs={12} md={5}>
        <Grid item xs={9}>
          <TextField
            fullWidth
            size="small"
            helperText={"*띄어쓰기 없는 영문, 숫자로만 6~20자"}
            type="text"
            className="signup-text-input"
            {...username}
            variant={"outlined"}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            onClick={idCheckHandler}
            variant={"contained"}
            className={"signup-btn"}
          >
            중복확인
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
