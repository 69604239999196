import React, { useState, useEffect } from 'react';
import '../../../../css/platform/functions/RTRanking.css';
import { RiVipCrownFill, RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import classNames from 'classnames';

function RTRanking(){

    const temp_data = [
        {"id": 1, "position": "영화의전당 야외광장", "count": "1,234명"},
        {"id": 2, "position": "해운대해수욕장", "count": "872명"},
        {"id": 3, "position": "광안리해수욕장", "count": "719명"},
        {"id": 4, "position": "신세계백화점", "count": "663명"},
        {"id": 5, "position": "KNN", "count": "623명"},
        {"id": 6, "position": "롯데백화점", "count": "581명"},
        {"id": 7, "position": "벡스코 제1전시관", "count": "541명"},
        {"id": 8, "position": "수변공원", "count": "384명"},
        {"id": 9, "position": "제로웹", "count": "224명"},
        {"id": 10, "position": "해운대구청", "count": "97명"},

    ]
    const selected_btn = {
        "num_of_pck": 1, "num_of_view": 2
    }
    const [selectedBtn, setSelectedBtn] = useState(selected_btn.num_of_pck);
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCount((count + 1)%10);
        }, 2000)
    }, [count]);

    function show_rank_more() {
        console.log("click");
        setShow(!show);
    }

    function btnHandler(key){
        setSelectedBtn(key);
    }

    const styles = { 
        transform: `translateY(${-count*50}px)`,
        color: `white`
    };

    return(
        <>
            <div id="ranking_wrapper" className={show? 'ranking_wrapper_na' : 'ranking_wrapper_a'}>
                {show ? 
                    <>
                        <div className="ranking_title" >
                            <div id="txt_bold">실시간 랭킹 
                                <div id="icon_wrap"><RiVipCrownFill size={20} color="black"/></div>
                                <div id="cur_location">부산 전체 기준</div>
                            </div> 
                        <span className={selectedBtn === selected_btn.num_of_pck ? 'selected' : ''} onClick={() => btnHandler(selected_btn.num_of_pck)}>유동인구 순</span>
                        <span className={selectedBtn === selected_btn.num_of_view ? 'selected' : ''} onClick={() => btnHandler(selected_btn.num_of_view)}>조회수 순</span>
                        <div className="under_line"></div>
                        <ul className="ranking_list">
                            {temp_data.map((data, index) => {
                                return <li key={index} className={(index === count && show) ? 'anime' : ''}>
                                        <span className="index">{data.id}</span>
                                        <span>{data.position}</span>
                                        <span>{data.count}</span> 
                                </li>
                            })}
                        </ul>
                        </div>
                    </>
                    : ''}
                <span className={show ? '' : 'twink'}></span>
                <ul className={classNames("ranking_list", show ? "list_close" : "list_show")}>
                    {temp_data.map((data, index) => {
                        return <li key={index} style={styles} onClick={show ? null : show_rank_more}>
                                <span className="index">{data.id}</span>
                                <span>{data.position}</span>
                                <span>{data.count}</span> 
                        </li>
                    })}
                </ul>
                <span className="show_moreRank_Btn" onClick={show_rank_more}>{show ? <RiArrowUpSLine size={30}/> : <RiArrowDownSLine size={30}/>}</span>
            </div>
        </>
    )
}

export default RTRanking;