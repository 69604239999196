export class Company {
  uuid: string;
  created_at: number;
  updated_at: number;
  deleted_at: number;
  id: number;
  name: string;
  address: string;
  business_type: string;
  business_scale: string;

  constructor(company: Company) {
    Object.assign(this, company);
  }
}