import React from "react";
import Profile from "../../../../../screens/platform/Profile";
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    OutlinedInput
} from "@material-ui/core";
import {inject} from "mobx-react";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {api_pcp} from "../../../../../api/api_pcp";

class TerminateCheckPwd extends React.Component{

    state = {
        userPwd: '',
        showPassword: false
    }

    confirm = () => {
        const username = this.props.sessionStore.user?.username;
        const password = this.state.userPwd;

        if(this.state.userPwd.length === 0){
            alert('비밀번호를 입력해주세요');
        }else{
            api_pcp.post('/v1/auth/sign-in', {username, password}).then((res) => {
                if (!res.ok) return alert('비밀번호를 확인해주세요.');
                return api_pcp.post(`/v1/auth/secession`);
            }).then((res) => {
                if (!res.ok) return alert('데이터를 불러오지 못했습니다.');
                this.props.history.push('./complete');
            }).catch(err => {
                console.log(err);
            })
        }
    }

    render() {
        return (
            <Profile>
                <div className="c_wrapper">
                    <div className="header">회원탈퇴</div>
                    <div className="info_txt">
                        <div className="sub_title">안전한 회원탈퇴를 위해, <span className="txt_bold">비밀번호</span>를 확인해주세요!</div>
                        <div>
                            · 비밀번호 확인 후 계정은 즉시 탈퇴됩니다.<br/>
                            · 탈퇴 후에는 동일 아이디로 다시 가입할 수 없으니 신중하게 선택하여 주세요.
                        </div>

                        <div style={{width: 700}}>
                            <TextField id="outlined-basic" label="아이디" variant="outlined" value={this.props.sessionStore.user?.username} disabled
                                       style={{width: 420, marginBottom: 15}}/>


                            <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password"  style={{paddingLeft: 5, paddingRight: 5, backgroundColor: "white"}}>비밀번호</InputLabel>
                                <OutlinedInput
                                    style={{width: 420}}
                                    id="outlined-adornment-password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.userPwd}
                                    onChange={(e) => {this.setState({userPwd: e.target.value})}}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {this.setState({showPassword: !this.state.showPassword})}}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Button variant="outlined" style={{width: 100, float: "right", marginTop: 100}} onClick={this.confirm}>회원 탈퇴</Button>
                        </div>
                    </div>
                </div>
            </Profile>
        )
    }
}

export default inject('sessionStore')(TerminateCheckPwd);