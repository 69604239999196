import { Button, Dialog } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "../css/modals/modal.css";

const SuccessModal = (props) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setOpen(true);
  }, []);

  const clickHandler = () => {
    history.push(props.redirectLink);
  };

  return (
    <Dialog open={open} maxWidth="sm">
      {/* <div className={"success-modal"}> */}
      <Grid container>
        <Grid item xs={12}>
          {props.component ? <props.component /> : <p>{props.msg}</p>}

          <div>
            {props.redirectLink ? (
              <Button onClick={clickHandler} variant={"contained"}>
                {props.redirectName}
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
      {/* </div> */}
    </Dialog>
  );
};

export default SuccessModal;
