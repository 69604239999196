import React from "react";
import {
    Button, Dialog, DialogContent, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput
} from "@material-ui/core";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";
import {rexEngNumSp} from "../constants/RegularEpressions";
import {api_pcp} from "../api/api_pcp";
import CompleteModal from "./CompleteModal";

export default class PasswordChangeModal extends React.Component{

    state ={
        currentPwd : '',
        newPwd1 : '',
        newPwd2 : '',
        validatePwd : false,
        confirm: false,
        secondModal: false,
        showPassword: {curPwd: false, newPwd1: false, newPwd2: false},
    }

    onChangeCurrentPwd = (e) => {
        this.setState({
            currentPwd: e.target.value,
        });
    }

    onChangeNewPwd1 = (e) => {
        let bol = false;
        if(this.state.newPwd1.length !== 0 &&  this.state.newPwd2.length !== 0 && e.target.value === this.state.newPwd2){
            bol = true;
        }

        this.setState({
            newPwd1: e.target.value,
            confirm: bol,
        }, this.setState({ validatePwd: rexEngNumSp.test(this.state.newPwd1)}));
    }

    onChangeNewPwd2 = (e) => {
        let bol = false;
        if(this.state.newPwd1.length !== 0 &&  this.state.newPwd2.length !== 0 && this.state.newPwd1 === e.target.value){
            bol = true;
        }
        this.setState({
            newPwd2: e.target.value,
            confirm: bol
        });
    }

    changePassword = (pwd = this.state.currentPwd, newPwd = this.state.newPwd2) => {
        api_pcp.put('/v1/user/me/password', {
            password: pwd,
            new_password: newPwd
        }).then((res) => {
            if (!res.ok) return alert('현재 비밀번호가 일치하지 않습니다');
            this.setState({
                secondModal: true
            })
        }).catch((err) => {
            console.err(err);
        })
    }

    closeModal = () => {
        this.initForm();
        this.props.close(false);
    }

    onClickChange = () => {
        const curPwd = this.state.currentPwd;
        const newPwd = this.state.newPwd2;
        if (!curPwd) return alert('현재 비밀번호를 입력해주세요');
    //    if (!this.state.validatePwd) return alert('비밀번호를 띄어쓰기 없는 영문, 숫자, 특수문자를 포함한 8~15자로 설정해주세요');
        if (!this.state.confirm) return alert('새로운 비밀번호가 일치하지 않습니다.');
        this.changePassword(curPwd, newPwd);
    }

    initForm = () => {
        this.setState({
            currentPwd : '',
            newPwd1 : '',
            newPwd2 : '',
            validatePwd : false,
            confirm: false,
            showPassword: {curPwd: false, newPwd1: false, newPwd2: false},
        })
    }

    render() {
        const { open } = this.props;
        return (
            <>
                <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={'md'}>
                    <DialogContent className="c-modal">
                        <button className="close-btn" onClick={this.closeModal}> &times; </button>
                        <div>
                            <div className="modal-title">비밀번호 변경</div>
                            <div className="mb-30">
                                개인정보 보호와 피해방지를 위해 6개월 단위로 주기적인 비밀번호 변경을 추천드립니다. <br/>
                                소중한 개인정보를 위해 비밀번호를 변경해 주세요!
                            </div>
                            <div>
                                <div className="mb-30">
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password"  style={{paddingRight: 10, marginTop: -5, backgroundColor: "white"}}>현재 비밀번호</InputLabel>
                                        <OutlinedInput
                                            type={this.state.showPassword.curPwd ? 'text' : 'password'}
                                            value={this.state.currentPwd}
                                            onChange={this.onChangeCurrentPwd}
                                            style={{ width: 400, height: 40 }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.setState({showPassword: {...this.state.showPassword, curPwd: !this.state.showPassword.curPwd}})}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {this.state.showPassword.curPwd ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div className="mb-30">
                                    <FormControl variant="outlined" style={{marginTop: 10}}>
                                        <InputLabel htmlFor="outlined-adornment-password"  style={{paddingRight: 10, marginTop: -5, backgroundColor: "white"}}>새 비밀번호</InputLabel>
                                        <OutlinedInput
                                            type={this.state.showPassword.newPwd1 ? 'text' : 'password'}
                                            value={this.state.newPwd1}
                                            onChange={this.onChangeNewPwd1}
                                            style={{ width: 400, height: 40 }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.setState({showPassword: {...this.state.showPassword, newPwd1: !this.state.showPassword.newPwd1}})}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {this.state.showPassword.newPwd1 ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="outlined-weight-helper-text" style={{color: this.state.newPwd1.length === 0 ? null : this.state.validatePwd? "green" : "red"}}>* 띄어쓰기 없는 영문, 숫자, 특수문자를 포함한 8~15자</FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="mb-30">
                                    <FormControl variant="outlined" style={{marginTop: -10}}>
                                        <InputLabel htmlFor="outlined-adornment-password"  style={{paddingRight: 10, marginTop: -5, backgroundColor: "white"}}>새 비밀번호 확인</InputLabel>
                                        <OutlinedInput
                                            type={this.state.showPassword.newPwd2 ? 'text' : 'password'}
                                            value={this.state.newPwd2}
                                            onChange={this.onChangeNewPwd2}
                                            style={{ width: 400, height: 40 }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => this.setState({showPassword: {...this.state.showPassword, newPwd2: !this.state.showPassword.newPwd2}})}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        {this.state.showPassword.newPwd2 ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="outlined-weight-helper-text" style={{color: this.state.newPwd2.length === 0 || this.state.confirm ? "green" : "red"}}>{this.state.newPwd2.length === 0 ? '' : this.state.confirm ? "비밀번호가 일치합니다" : "비밀번호가 일치하지 않습니다."}</FormHelperText>
                                    </FormControl>
                                </div>
                                <Button variant="outlined" onClick={this.onClickChange} style={{width: 150, position: "absolute", right: 40, bottom: 40}}>비밀번호 변경</Button>
                            </div>
                        </div>
                        <CompleteModal open={ this.state.secondModal } close={ () => this.setState({secondModal: false}) } parentClose={this.closeModal}>
                            비밀번호 변경이 완료되었습니다!
                        </CompleteModal>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}