import { useContext, useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { api_pcp } from "../../api/api_pcp";
import { Loading } from "../../components/utilities/Loading";
import { AlertContext } from "../../contexts/AlertContext";
import FindIdEmail from "../../components/auth/findid/FindIdEmail";
import FindIdPhoneNumber from "../../components/auth/findid/FindIdPhoneNumber";
import Timer from "../../components/utilities/Timer";
import NumberFormat from "react-number-format";
import OrgAndCompModal from "../../modals/OrgAndCompModal";
import UserListModal from "../../modals/UserListModal";

import "../../css/authentication/find-id.css";

const FindId = () => {
  const [profile, setProfile] = useState({
    phone_number: "",
    email: "",
    organization_uuid: "",
    company_uuid: "",
    recovery_type: "phone-number",
    agency_name: "",
    auth_code: "",
    request_key: "",
  });
  const [timer, setTimer] = useState(false);
  const [modal, setModal] = useState(false);
  const [agencyModal, setAgencyModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const setAlert = useContext(AlertContext).setAlert;

  const submitClickHandler = () => {
    console.log(profile);
    if (!loading) {
      if (!profile.auth_code || !profile.agency_name) {
        setAlert({
          type: "error",
          message: "Please fill required fields",
        });
        return;
      }
      if (profile.recovery_type === "phone-number") {
        if (!profile.phone_number) {
          setAlert({
            type: "error",
            message: "Please enter phone number",
          });
          return;
        }
      } else {
        if (!profile.email) {
          setAlert({
            type: "error",
            message: "Please enter email",
          });
          return;
        }
      }

      setLoading(true);

      api_pcp.post("/v1/username/find", profile).then((r) => {
        if (!r.ok) {
          return;
        }
        setTimer(false);
        setUsers(r.data.users);
        setAlert({
          type: "success",
          message: "Verified",
        });
        setTimeout(() => {
          setModal(true);
          setLoading(false);
        }, 1000);
      });
    }
  };

  const onChange = (e) => {
    const temp = { ...profile };
    temp.recovery_type = e.target.value;
    setProfile(temp);
    setTimer(false);
  };

  const AgencyModalHandler = () => {
    setAgencyModal(!agencyModal);
  };

  const keyChangeHandler = (e) => {
    const temp = { ...profile };
    temp.auth_code = e.target.value;
    setProfile(temp);
  };

  return (
    <Container maxWidth="md" style={{ padding: "0 2rem" }}>
      {loading && <Loading />}
      {modal && <UserListModal list={users} />}
      <Grid container spacing={2} paddingTop={"4rem"}>
        <Grid item xs={12}>
          <h2 className={"find-id-title"}>아이디 찾기</h2>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            className="find-id-radio-wrapper"
            row
            aria-label="position"
            onChange={onChange}
            name="member-type"
            value={profile.recovery_type}
          >
            <FormControlLabel
              value="phone-number"
              control={<Radio color="primary" />}
              label="등록된 휴대폰 번호로 찾기 "
            />
            <FormControlLabel
              value="email"
              control={<Radio color="primary" />}
              label="등록된 이메일로 찾기"
            />
          </RadioGroup>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <OrgAndCompModal
              open={agencyModal}
              handleClose={AgencyModalHandler}
              profile={profile}
              setProfile={setProfile}
            />
            <Grid item xs={12} md={1}>
              <h2>소속</h2>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                id="outlined-multiline-flexible"
                //   label="소속기관을 입력해주세요!"
                value={profile.agency_name}
                variant="outlined"
                className={"org-name"}
                InputProps={{
                  readOnly: true,
                }}
                onClick={AgencyModalHandler}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {profile.recovery_type === "phone-number" ? (
            <FindIdPhoneNumber
              profile={profile}
              setProfile={setProfile}
              timer={timer}
              setTimer={setTimer}
              setAlert={setAlert}
            />
          ) : (
            <FindIdEmail
              profile={profile}
              setProfile={setProfile}
              submitButton
              timer={timer}
              setTimer={setTimer}
              setAlert={setAlert}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={6} md={2}>
              <TextField
                required
                fullWidth
                id="standard-required"
                label="인증번호입력"
                value={profile.auth_code}
                onChange={keyChangeHandler}
                helperText="*인증번호 6자리를 입력해주세요!"
                InputProps={{
                  inputComponent: NumberFormatCustom2,
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              md={1}
              alignSelf={"center"}
              justifyContent={"center"}
            >
              <div className={"timer"}>
                {timer ? <Timer seconds={180} /> : null}
              </div>
            </Grid>
            <Grid item xs={3} md={1} alignSelf={"center"}>
              <Button
                onClick={submitClickHandler}
                fullWidth
                variant={"contained"}
              >
                확인
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="######"
    />
  );
}

export default FindId;
