import React, { useEffect, useMemo, useRef } from "react";
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import * as L from "leaflet";
import Profile from "../../../../../screens/platform/Profile";
import { api_juso } from "../../../../../api/api_juso";
import classNames from "classnames";
import proj4 from "proj4";
import RegisterPlaceModal from "../../../../../modals/RegisterPlaceModal";
import FileDAD from "./FileDAD";

export default class PlaceRegister extends React.Component {
  bol = false;
  imgRef = React.createRef();
  addressListRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      placeList: ["해당없음", "영화의 전당", "야외광장", "비프힐"],
      serial: "",
      img_name: "",
      isOpen: false,
      address: "",
      addressList: [],
      isSearch: false,
      coordinate: this.defaultCoordinate,
      isAvailable: false,
      addPlaceModal: false,
      selectPlaceValue: "",
      selectOpen: false,
      isDrag: false,
    };
  }

  defaultCoordinate = [35.172812929631704, 129.12573121341853];
  koreaBounds = [
    [45, 148],
    [28, 105],
  ];

  componentDidMount() {
    document.addEventListener("mousedown", (e: any): void => {
      if (this.state.isSearch && !this.addressListRef.current.contains(e.target)) {
        this.setState({ isSearch: false });
      }
    });
  }

  onPinChange = (e) => {
    this.setState({ serial: e.target.value.replace(/[^0-9]/g, "") });
  };

  pinReset = () => {
    this.setState({ serial: "" });
  };

  onAddressKeyPress = (e) => {
    if (e.key === "Enter") {
      this.searchPlace();
      this.setState({ isSearch: true });
    }
  };
  onAddressChange = (e) => {
    this.setState({ address: e.target.value });
  };

  searchPlace = () => {
    api_juso
      .get("https://www.juso.go.kr/addrlink/addrLinkApi.do", {
        currentPage: 1,
        countPerPage: 50,
        keyword: this.state.address,
        resultType: "json",
      })
      .then((res) => {
        if (!res.ok) {
          console.warn(res);
          return;
        }
        this.setState({
          addressList: res.data.results.juso,
          isSearch: true,
        });
      });
  };

  selectPlace = (index) => {
    let selected = this.state.addressList[index];
    this.setState({
      isSearch: false,
      address: selected.jibunAddr,
    });

    api_juso
      .get("https://www.juso.go.kr/addrlink/addrCoordApi.do", {
        type: "address",
        admCd: selected.admCd,
        rnMgtSn: selected.rnMgtSn,
        udrtYn: 0,
        buldMnnm: selected.buldMnnm,
        buldSlno: selected.buldSlno,
        resultType: "json",
      })
      .then((res) => {
        if (!res.ok) {
          console.warn(res);
          return;
        }
        let coordinate = proj4(
          "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
        ).inverse([Number.parseFloat(res.data.results.juso[0].entX), Number.parseFloat(res.data.results.juso[0].entY)]);
        coordinate.reverse();
        this.setState({ coordinate });
      });
  };

  loadImg = (e) => {
    if (e.target?.files[0]?.name) this.setState({ img_name: e.target.files[0].name });

    if (e?.name) this.setState({ img_name: e.name });
  };

  removeImg = () => {
    this.imgRef.current.disabled = true;
    this.imgRef.current.value = "";
    this.setState({ img_name: "" });

    setTimeout(() => {
      this.imgRef.current.disabled = false;
    }, 50);
  };

  setPlace = (place) => {
    this.setState({ address: place }, this.searchPlace);
  };

  onChangeSelect = (e) => {
    if (e.target.value === -1) {
      this.setState({
        addPlaceModal: true,
        selectPlaceValue: "",
      });
    } else {
      this.setState({ selectPlaceValue: e.target.value });
    }
  };

  isDrag = (bol) => {
    this.setState({ isDrag: bol });
  };

  render() {
    return (
      <Profile>
        <div className="c_wrapper" style={{ width: 1500 }}>
          <div style={{ fontWeight: "bold", fontSize: 30, marginBottom: 20 }}>센서 등록</div>
          <div className="">
            센서를 설치한 후, 센서를 플랫폼에 등록하는 페이지입니다. 센서가 아직 준비되지 않았다면, 센서를 먼저 구매하고
            설치해주세요!
          </div>
          <div style={{ marginTop: 50 }}>
            <div className="sensor_wrapper">
              <div>BIFF 총 센서 수</div> <div className="sensor_icon">센서 아이콘</div> <div>51개</div>
            </div>
            <div className="sensor_wrapper">
              <div>BIFF 분석 공간 수</div> <div className="sensor_icon">공간 아이콘</div> <div>35개</div>
            </div>
          </div>
          <div className="txt_gray">
            현재 보유 중인 센서는 51개! 이 센서로 총 <span style={{ textDecoration: "underline" }}>35곳의 장소</span>가
            분석되고 있습니다.
          </div>
          <div className="input_sensor_wrapper" style={{ paddingBottom: 20 }}>
            <div className="sub_title txt_bold" style={{ marginTop: 70 }}>
              STEP 1. 센서 설치 사진 등록
            </div>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                component="label"
                disableElevation
                style={{ marginRight: 10, height: 36, backgroundColor: this.state.isDrag ? "#ddd" : "#eee" }}
              >
                <FileDAD handleDrop={this.loadImg} isDrag={this.isDrag}>
                  <label id="sensor_file_label" htmlFor="sensor_file">
                    {this.state.img_name ? (
                      this.state.img_name
                    ) : (
                      <span style={{ color: "#888" }}>파일(.png/.jpeg/.pdf)을 마우스로 끌어서 넣어주세요</span>
                    )}
                  </label>
                </FileDAD>

                <input
                  type="file"
                  hidden
                  onChange={this.loadImg}
                  id="sensor_file"
                  ref={this.imgRef}
                  accept="image/png, image/jpeg, application/pdf"
                />
                {this.state.img_name === "" ? null : (
                  <Button id="remove_btn" onClick={this.removeImg} style={{ position: "absolute", right: 10 }}>
                    &times;
                  </Button>
                )}
              </Button>
              <Button variant="outlined" onClick={this.removeImg}>
                {" "}
                사진 등록은 다음에 할래요
              </Button>
            </div>

            <div className="sub_title txt_bold" style={{ marginTop: 50 }}>
              STEP 2. 센서 PIN 번호 입력
            </div>
            <div>
              <TextField
                id="sensor-serial"
                autoComplete="off"
                size="small"
                style={{ height: 30, border: "none" }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: { maxLength: 4 },
                }}
                onInput={this.onPinChange}
                value={this.state.serial}
              />
              <Button onClick={this.pinReset} style={{ marginLeft: 10 }}>
                지우기
              </Button>
              <Button variant="outlined" style={{ width: 100, marginLeft: 10 }}>
                확인
              </Button>
              <div style={{ color: "blue", fontSize: 14, marginTop: 10 }}>
                * 센서 뒷족의 PIN 번호를 확인해주세요!{" "}
                <span style={{ textDecoration: "underline", cursor: "pointer" }}>PIN번호가 없나요?</span>
              </div>
            </div>
            <div className="sub_title txt_bold" style={{ marginTop: 50 }}>
              STEP 3. 장소/공간 정보 입력
            </div>
            <div style={{ position: "relative" }}>
              <div style={{ display: "inline-block", position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  autoComplete="off"
                  label="장소/공간 이름"
                  variant="outlined"
                  size="small"
                  style={{ width: 420, height: 40 }}
                />
                <div style={{ position: "absolute", right: 20, top: 10 }}>
                  <label>
                    <input type="radio" name="in-out" value="indoor" defaultChecked /> 실내
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label>
                    <input type="radio" name="in-out" value="outdoor" /> 실외
                  </label>
                </div>
              </div>
              <FormControl variant="outlined">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{ marginLeft: 20, marginBottom: 20, lineHeight: 0 }}
                >
                  장소 Group 설정
                </InputLabel>
                <Select
                  style={{ width: 300, height: 40, marginLeft: 20, marginBottom: 20 }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="장소 Group 설정"
                  onChange={this.onChangeSelect}
                  value={this.state.selectPlaceValue}
                  defaultValue={``}
                  open={this.state.selectOpen}
                  onClose={() => this.setState({ selectOpen: false })}
                  onOpen={() => this.setState({ selectOpen: true })}
                >
                  {this.state.placeList.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                  <MenuItem
                    value={-1}
                    style={{ color: "blue", fontWeight: "bold" }}
                    onClick={() => {
                      this.setState({ selectOpen: true });
                    }}
                  >
                    + 장소 그룹 추가
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                autoComplete="off"
                label="주소"
                variant="outlined"
                size="small"
                style={{ width: 600, height: 40 }}
                onKeyPress={this.onAddressKeyPress}
                value={this.state.address}
                onChange={this.onAddressChange}
              />
              <Button variant="outlined" style={{ height: 40, width: 120, marginLeft: 20 }} onClick={this.searchPlace}>
                주소 검색
              </Button>

              <div
                className={classNames("address_list", this.state.isSearch ? null : "address_list_none")}
                ref={this.addressListRef}
              >
                {this.state.addressList ? (
                  <List dense={false} style={{ height: "100%", overflow: "auto" }}>
                    {this.state.addressList.map((item, index) => {
                      return (
                        <ListItem
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.selectPlace(index)}
                          value={item.roadAddr}
                        >
                          <ListItemText primary={item.roadAddr} secondary={item.jibunAddr} />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div className="no_address">주소가 너무 짧거나(2자 이상) 존재하지 않습니다.</div>
                )}
              </div>
              <MapContainer
                center={[35.172812929631704, 129.12573121341853]}
                zoom={17}
                zoomControl={false}
                zoomSnap={0.5}
                scrollWheelZoom={true}
                zoomAnimation={true}
                maxBounds={this.koreaBounds}
                id="mini_map"
              >
                <TileLayer
                  attribution='&copy; <a href="https://map.vworld.kr" target="_blank">vworld</a> contributors'
                  url="https://api.vworld.kr/req/wmts/1.0.0/6A16D893-B0C9-3AC1-A2AB-25C70B2D480D/Base/{z}/{y}/{x}.png"
                  minZoom="7"
                  maxZoom="19"
                />
                <MapController cords={this.state.coordinate} setPlace={this.setPlace} />
              </MapContainer>
            </div>
          </div>
          <Button
            variant="outlined"
            onClick={this.registerSensor}
            style={{ width: 100, marginBottom: 50, marginLeft: 720 }}
          >
            등록
          </Button>
        </div>
        {this.state.addPlaceModal && (
          <RegisterPlaceModal
            open={this.state.addPlaceModal}
            close={() => this.setState({ addPlaceModal: false })}
            addPlace={this.addPlace}
          ></RegisterPlaceModal>
        )}
      </Profile>
    );
  }
  addPlace = (item) => {
    console.log(item);
    this.setState({
      placeList: [...this.state.placeList, item],
    });
  };

  registerSensor = () => {
    this.setState(
      {
        serial: "",
        img_name: "",
        isOpen: false,
        address: "",
        addressList: [],
        isSearch: false,
        coordinate: this.defaultCoordinate,
        isAvailable: false,
        addPlaceModal: false,
        selectPlaceValue: "",
        selectOpen: false,
        isDrag: false,
      },
      () => {
        alert("등록되었습니다");
      }
    );
  };
}

export const MapController = (props) => {
  const map = useMap();
  const markerRef = useRef(null);
  //    const [position, setPosition] = useState(props.cords);

  useEffect(() => {
    map.setView(props.cords, 18);
  }, [props.cords]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          //setPosition(marker.getLatLng());
          api_juso
            .get("https://dapi.kakao.com/v2/local/geo/coord2address.json", {
              type: "coordToAddr",
              input_coord: "WGS84",
              x: marker.getLatLng().lng,
              y: marker.getLatLng().lat,
            })
            .then((res) => {
              props.setPlace(
                res.data.documents[0]?.road_address?.address_name
                  ? res.data.documents[0].road_address.address_name
                  : res.data.documents[0]?.address?.address_name
                  ? res.data.documents[0]?.address.address_name
                  : "마커를 다시 이동해주세요"
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    }),
    []
  );

  return (
    <Marker
      draggable
      position={props.cords}
      autoPan={true}
      ref={markerRef}
      eventHandlers={eventHandlers}
      icon={L.divIcon({
        //html: `I'm Here`,
        html: `I'm here`,
        className: "im_here_btn",
        iconSize: L.point(80, 30),
        iconAnchor: [40, 30],
      })}
    />
  );
};
