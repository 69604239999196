
import { useEffect, useRef, useState } from "react";
import { GeoJSON, useMap } from "react-leaflet";

const LineLayer = (props) => {
    const [data, setData] = useState();
    const lineRef = useRef();
    const map = useMap();

    useEffect(() => {
        setTimeout(() => {
            setData(props.data);
            if (lineRef.current){
                const bounds = lineRef.current.getBounds();
                map.flyToBounds(bounds)
            }
            
        }, 10);
        
    }, [map, props.data])

    return(
        <>
            {data ? <GeoJSON 
                        key={"line-data-" + data.features[0].properties.ctprvn_cd? data.features[0].properties.ctprvn_cd: data.features[0].properties.sig_cd}
                        data = {props.data}
                        // onEachFeature = {onEachRegion}
                        style = {b}
                        ref={lineRef}
                     /> : null}
        </>
    )
}




const b = () => {
    return {
        color: "#2E79EBc0",
        weight: 2,
        fillOpacity: 0.05
    }
}

export default LineLayer;