import React from "react";
import Profile from "../../../../../screens/platform/Profile";
import {Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Checkbox} from "@material-ui/core";
import {inject} from "mobx-react";
import { api_pcp } from '../../../../../api/api_pcp';
import NoTerminateModal from "../../../../../modals/NoTerminateModal";

class TerminateGuidance extends React.Component{

    state = {
        readAconfirm: false, // 탈퇴에 동의 여부
        inputable: false, // 탈퇴 사유에서 직접 입력 여부
        terminable: true, // 탈퇴 가능 여부 ( 마스터 계정이 하나 인지 )
        groupName: '',
    }

    readOk = (e) => {
        this.setState({readAconfirm: e.target.checked});
    }

    terminateOk = () => {
        if(this.state.readAconfirm){
            this.props.history.push('./terminate/check-pwd');
        }else{
            alert('동의함에 체크해주세요');
        }
    }

    onRadioChange = (e) => {
        if(parseInt(e.target.value) === 6){
            this.setState({inputable: true});
        }else{
            this.setState({inputable: false});
        }
    }

    componentDidMount() {
        this.props.sessionStore.fetchMe().then(() => {
            let user = this.props.sessionStore.user;
            if(user.type === "SUPER_USER"){
                let group = user.company_uuid ? "company" : "organization";
                api_pcp.get(`/v1/user/me/${group}/user/`, ).then((res) => {
                    if (!res.ok) return alert('데이터를 확인 못했습니다.');
                    let numberMasters = 0;
                    (res.data.users || []).forEach((e) => {
                        if(e.type === "SUPER_USER") ++numberMasters
                    });

                    if(numberMasters === 1){
                        this.setState({
                            terminable : false,
                            groupName : this.props.sessionStore.user.company.name
                        });
                    }
                }).catch((err) => {
                    console.error(err);
                })
            }
        });

    }

    render(){
        return (
            <Profile>
                <div className="c_wrapper">
                    <div className="header">회원탈퇴</div>
                    <div className="info_txt">
                        <div className="sub_title">회원탈퇴에 앞서 <span className="txt_bold">유의사항 및 안내</span>를 반드시 읽고 진행해 주세요!</div>
                        <div>
                            <div>· 회원탈퇴 진행시 사용하고 계신 계정(<span className="txt_bold">{this.props.sessionStore.user?.username}</span>)은 탈퇴할 경우 재사용 및 복구가 불가능합니다.</div>
                            <div style={{fontSize: 12, color: "red"}}>※ 탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가능합니다!</div>
                        </div>
                        <div>· 내 정보 및 개인 플랫폼 서비스 이용기록이 모두 삭제되며, 삭제되는 데이터를 미리 확인하여주세요</div>

                        <table>
                            <tbody>
                            <tr>
                                <td>센서</td>
                                <td>
                                    현재 계정에 등록되어 있는 보유 센서, 공유 받은 센서, 공유 중인 센서, 공유 요청 중인 센서 정보 삭제<br/>
                                    <div style={{fontSize: 12, color: "red"}}>※ 센서 등록 정보는 소속기관으로 등록되기 대문에 현 계정의 정보만 삭제되며, 실제로 등록된 센서의 정보가 삭제되진 않습니다.</div>
                                </td>
                            </tr>
                            <tr>
                                <td>결제 기록</td>
                                <td>삭제되는 데이터</td>
                            </tr>
                            <tr>
                                <td>데이터 추출 기록</td>
                                <td>PCP 플랫폼 내에서 확인 및 추출 가능한 '유동인구 - 유동인구 데이터'와 '유동인구 - **데이터 상관관계'의 데이터 엑셀시트 및 Report PDF 다운로드 기록</td>
                            </tr>
                            </tbody>
                        </table>
                        <FormControlLabel style={{float: "right"}} control={<Checkbox onChange={this.readOk} name="checkConfirm" />} label="안내 사항을 모두 확인하였으며, 이에 동의합니다" />
                    </div>
                </div>
                <div className="terminate_reason">
                    <FormControl component="fieldset" style={{display: "block"}}>
                        <FormLabel component="legend">· PCP는 더 나은 서비스를 제공해 드리기 위하여 탈퇴 사유에 대한 조사를 실시하고 있습니다!</FormLabel>
                        <RadioGroup aria-label="reason" name="reason" onChange={this.onRadioChange}>
                            <FormControlLabel value="1" control={<Radio />} label="사이트 사용 빈도 낮음" />
                            <FormControlLabel value="2" control={<Radio />} label="서비스의 잦은 오유로 인한 불만족" />
                            <FormControlLabel value="3" control={<Radio />} label="개인정보 보호 차원" />
                            <FormControlLabel value="4" control={<Radio />} label="데이터 및 콘텐츠 부족" />
                            <FormControlLabel value="5" control={<Radio />} label="유료정책에 대한 가격 부담" />
                            <FormControlLabel value="6" control={<Radio />} label={<TextField id="outlined-basic" autoComplete="off" label="직접입력" variant="outlined" size="small" style={{width: 650, height: 40}} disabled={!this.state.inputable}/>}></FormControlLabel>
                        </RadioGroup>
                    </FormControl>
                    <Button variant="outlined" onClick={this.terminateOk} style={{width: 100, float:"right"}}>회원탈퇴</Button>
                </div>

                <NoTerminateModal open={!this.state.terminable} history={this.props.history} groupName={this.state.groupName}/>
            </Profile>
        )
    }
}

export default inject(['sessionStore'])(TerminateGuidance);