import React from 'react';
import Chart from "react-apexcharts";

/*
    progress floation population chart for today.
    추이별(일별, 주별, 월별) 유동인구 데이터 그래프
*/

class BarChartProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        name: 'Inflation',
        data: [12, 11, 14, 18, 17, 13, 13, 12]
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: ["7.01", "7.02", "7.03" , "7.04" , "7.05" , "7.06" , "7.07" , "7.08"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: { /* 플로팅 창에 대한 설정 */
            show: false,
            formatter: function (val) {
              return val + "명";
            }
          }
        },
      },
    };
  }

    render() {
      return (
        <div id="chart">
            <Chart options={this.state.options} series={this.state.series} type="bar" height={300} />
        </div>
      );
    }
  }

export default BarChartProgress;