import { useContext, useEffect, useState } from "react";
import { Button, Container, TextField } from "@material-ui/core";
import { api_pcp } from "../../../api/api_pcp";
import { AlertContext } from "../../../contexts/AlertContext";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import "../../../css/authentication/find-id.css";

const FindIdPhoneNumber = (props) => {
  const [phoneNumber, setPhoneNumber] = useState(props.profile.phone_number);
  const setTimer = props.setTimer;
  const setAlert = useContext(AlertContext).setAlert;
  let profile = props.profile;

  const onChange = (e) => {
    let value = e.target.value;
    value = value.replaceAll("_", "");
    value = value.replaceAll("-", "");
    setPhoneNumber(value);
  };

  const onClick = () => {
    if (phoneNumber === "" || phoneNumber.length < 11) {
      setAlert({
        type: "error",
        message: "Please enter full cellphone number",
      });
      setTimer(false);
      return;
    }

    if (props.resetPassword) {
      api_pcp
        .post("/v1/password/reset/auth-code", {
          phone_number: phoneNumber,
          username: profile.username,
        })
        .then((r) => {
          if (!r.ok) {
            setAlert({
              type: "error",
              message: "Given parameters is not valid",
            });
            setTimer(false);
            return;
          }
          console.log("succes");
          setAlert({
            type: "success",
            message:
              "We have sent you verification key via sms, enter it to continue",
          });
          profile.request_key = r.data?.request_key;
          props.setProfile(profile);
        });
    } else {
      setAlert({
        type: "success",
        message:
          "We have sent you verification key via sms, enter it to continue",
      });
    }

    if (props.timer) {
      setTimer(false);
      setTimeout(() => {
        setTimer(true);
      }, 1000);
    } else {
      setTimer(true);
    }
  };

  useEffect(() => {
    profile.phone_number = phoneNumber;
    props.setProfile(profile);
  }, [props, phoneNumber]);

  return (
    // <div className={"input-group"}>
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={12} md={1}>
        <h3>휴대폰</h3>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <TextField
              fullWidth
              className={"signup-text-input"}
              label="010-0000-0000"
              value={phoneNumber}
              onChange={onChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              onClick={onClick}
              variant={"contained"}
              className={"btn-primary"}
            >
              인증
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // </div>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###-####-####"
      mask="_"
    />
  );
}

export default FindIdPhoneNumber;
