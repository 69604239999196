import axios from "axios";
import {create} from "apisauce";

const baseURL = 'https://api.pcp.zeroweb.cloud';
// const baseURL: string = 'http://localhost:3330';

const axiosInstance = axios.create({ timeout: 30000, baseURL, headers: {

    }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(async (config) => {
  // const httpMetric = firebase.perf().newHttpMetric(config.url, config.method.toUpperCase());
  // config.metadata = { httpMetric };

    /*
    *   params.type = "" // 주소 얻기 위한 토큰               => "U01TX0FVVEgyMDIxMDcyOTEwMTkyMTExMTQ2NjU="
    *   params.type = "address" // 맵에 좌표 얻기 위한 토큰    => U01TX0FVVEgyMDIxMDcyOTE1MzE1MDExMTQ2ODI=
    */

    let type = config.params.type;
    delete config.params.type;

    if(type === "coordToAddr"){
        config.headers = {Authorization: 'KakaoAK d0a404692663b4cf7d20f5975045b40d'};
    }else{
        Object.assign(config.params, {
            confmKey: type === "address" ? 'U01TX0FVVEgyMDIxMDcyOTE1MzE1MDExMTQ2ODI=' : "U01TX0FVVEgyMDIxMDcyOTEwMTkyMTExMTQ2NjU=",
        })
    }


  // add any extra metric attributes if needed
  // await httpMetric.putAttribute('baseURL', config.baseURL);
  // await httpMetric.putAttribute('User-Agent', config.headers['User-Agent']);
  // await httpMetric.start();

  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async (response) => {
    // Request was successful, e.g. HTTP code 200s
    console.log('axios.response:onFulfilled():response', response);
    // const { httpMetric } = response.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('baseURL', baseURL);

    // await httpMetric.setHttpResponseCode(response.status);
    // await httpMetric.setResponseContentType(response.headers['content-type']);
    // await httpMetric.stop();

    return response;
  },
  async (error) => {
    // Request failed, e.g. HTTP code 500
    console.warn('axios.response:onRejected():error', error);
    // const { httpMetric } = error.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('userId', '12345678');

    // await httpMetric.setHttpResponseCode(error.response.status);
    // await httpMetric.setResponseContentType(error.response.headers['content-type']);
    // await httpMetric.stop();

    return Promise.reject(error);
  },
);

export const api_juso = create({ axiosInstance });