import React, { Component } from 'react'

export default class FileDAD extends Component {

    dropRef = React.createRef()
    dragCounter = 0;

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.props.isDrag(true);
        }
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--

        if (this.dragCounter === 0) {
            this.props.isDrag(false);
        }
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})

        let filename = e.dataTransfer.files[0].name.slice(-5);
        let exe = filename.substr(filename.indexOf('.')+1).toString();

        if(e.dataTransfer.files.length > 1)
            alert('여러개의 파일 업로드 시 맨처음꺼만 등록됩니다');
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            if(exe !== "png" && exe !== "PNG" && exe !== 'JPEG' && exe !== 'jpeg' && exe !== 'PDF' && exe !== 'jpeg')
                return alert('유효하지 않은 확장자입니다');
            this.props.handleDrop(e.dataTransfer.files[0]);
            this.props.isDrag(false);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }
    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }
    render() {
        return (
            <div ref={this.dropRef} >
                {this.props.children}
            </div>
        )
    }
}