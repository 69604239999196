import { useContext, useState } from "react";
import { useFormInput } from "../utils/InputUtils";
import { api_pcp } from "../api/api_pcp";
import { AlertContext } from "../contexts/AlertContext";
import { Button, Container, Dialog, TextField } from "@material-ui/core";
import Grid from "@mui/material/Grid";

import "../css/authentication/member-info.css";

export const OrganizationSelectModal = (props) => {
  const searchTerm = useFormInput("", { onPressEnter: updateOrganizations });
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedOrgUUID, setSelectedOrgUUID] = useState("");
  const [isLockedSubmit, setIsLockedSubmit] = useState(false);
  const setAlert = useContext(AlertContext).setAlert;
  const setOpen = props.setOpen;
  const open = props.open;

  function updateOrganizations() {
    const query = searchTerm.value;
    if (!query) {
      setAlert({
        type: "error",
        message: "Please enter search query",
      });
      setIsLockedSubmit(false);
      return;
    }

    if (!isLockedSubmit) {
      setIsLockedSubmit(true);
      api_pcp.get("/v1/organization", { q: query }).then((r) => {
        setIsLockedSubmit(false);
        if (!r.ok) {
          console.log(r);
          return;
        }
        setOrganizations(r.data.organizations);
      });
    }
  }

  function submitClickHandler() {
    props.setName(selectedOrg);
    props.setUUID(selectedOrgUUID);
    handleClose();
  }

  function orgSelectHandler(e) {
    const value = e.target.value.split("$");
    setSelectedOrg(value[0]);
    setSelectedOrgUUID(value[1]);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const orgList = organizations.map((obj, i) => {
    return (
      <div key={"org-" + i}>
        <input
          type="radio"
          id={"org-item-" + i}
          value={obj.name + "$" + obj.uuid}
          name="org-item"
        />
        <label htmlFor={"org-item-" + i}>
          <span className="col-4-child">{obj.name.split("")[0]}</span>
          <span className="col-8-child">{obj.name}</span>
        </label>
      </div>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="id-validation-dialog"
    >
      <Container maxWidth="sm">
        <Grid container spacing={1} className="org-modal-container">
          <Grid item xs={12}>
            <h3 className="info-header">
              찾고자 하는 단체/기관의 이름을 검색해주세요
            </h3>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={9}>
              <TextField
                helperText={"*두 단어 이상 입력해주세요."}
                autoFocus
                fullWidth
                size="small"
                type="text"
                variant={"outlined"}
                className={"signup-text-input"}
                {...searchTerm}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                onClick={updateOrganizations}
                className="signup-btn"
                variant={"contained"}
              >
                검색
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 className="info-header">해당하는 단체/기관을 선택해 주세요</h3>
          </Grid>
          <Grid item xs={12}>
            <div className="org-list-wrapper">
              <div className="org-list-header">
                <span className="col-4">지역</span>
                <span className="col-8">단체/기관 명</span>
              </div>
              <div className="org-list-body" onChange={orgSelectHandler}>
                {orgList.length > 0 ? (
                  orgList
                ) : (
                  <div className="org-empty-list">
                    <span>
                      검색하신 내용이 없습니다! <br />
                      기업 이름을 다시 한번 확인해주세요.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={6}>
              <Button
                onClick={handleClose}
                className="signup-btn"
                variant={"outlined"}
                fullWidth
              >
                닫기
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={submitClickHandler}
                className="signup-btn"
                variant={"contained"}
              >
                확인 !
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};
